<template>
  <v-container fluid>
    <v-card elevation="10">
      <v-card-title class="blue--text">
        <h2>Packages</h2>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table :headers="headers" :items="packages" :search="search">
        <template v-slot:item.actions="{ item }">
          <v-menu app offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                slot="activator"
                color="primary"
                icon
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list dense>
              <v-list-item link>
                <v-list-item-title
                  ><v-icon style="height: 16px; font-size: 16px"
                    >mdi-archive-edit-outline</v-icon
                  >
                  <v-dialog v-model="dialog" persistent width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        color="white"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        style="text-transform: unset; color: black"
                        class="black--text"
                        @click="takeId(item.uniqueId)"
                      >
                        Edit package
                      </span>
                    </template>

                    <v-card color="rgba(255,255,255,1)" v-if="packageDetails">
                      <v-card-title class="text-h5 text-center">
                        <span>
                          <v-icon size="30" color="blue">
                            mdi-archive-edit-outline
                          </v-icon>
                        </span>

                        <h5 class="text-center blue--text mx-2">
                          Edit Package
                        </h5>
                      </v-card-title>

                      <v-card-text>
                        <v-text-field
                          label="Package Name"
                          v-model="packageDetails.packageName"
                          outlined
                          dense
                        />

                        <v-select
                          :items="categories"
                          label="Package category"
                          dense
                          v-model="packageDetails.packageCategory"
                          outlined
                          :value="packageDetails.packageCategory"
                        />

                        <v-text-field
                          label="Number of surveys"
                          v-model="packageDetails.packageNumberSurveys"
                          type="number"
                          outlined
                          dense
                        />

                        <v-text-field
                          label="Number of colaborators"
                          v-model="packageDetails.packageNumberCollaborators"
                          type="number"
                          outlined
                          dense

                        />

                        <v-text-field
                          label="Package duration"
                          v-model="packageDetails.packageDuration"
                          type="number"
                          outlined
                          dense
                        />

                        <v-select
                          :items="durationType"
                          label="Package duration type"
                          dense
                          v-model="packageDetails.packageDurationType"
                          :value="packageDetails.packageDurationType"
                          outlined
                        />

                        <!---  End of Package duration --->
                        <!-- Storage --->
                        <v-text-field
                          label="Storage"
                          v-model="packageDetails.packageStorage"
                          type="number"
                          outlined
                          dense
                          :value="packageDetails.packageStorage"
                        />

                        <v-select
                          :items="storageTypes"
                          label="Package storage type"
                          dense
                          v-model="packageDetails.packageStorageType"
                          outlined
                          :value="packageDetails.packageStorageType"
                        />

                        <v-text-field
                          label="Amount"
                          v-model="packageDetails.packageAmount"
                          type="number"
                          outlined
                          dense
                          :value="packageDetails.packageAmount"
                        />
                    
                      </v-card-text>
          
                      <v-card-text> </v-card-text>
                      <v-card-actions class="mx-3">
                        <v-spacer></v-spacer>
                        <v-btn color="red " class="white--text" @click="dialog = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary "
                          @click="editPackage(packageDetails)"
                        >
                          save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog></v-list-item-title
                ></v-list-item
              >
              <v-list-item link @click="deletePackage(item.uniqueId)">
                <v-list-item-title class="red--text"
                  ><v-icon style="height: 16px; font-size: 16px" color="red"
                    >mdi-trash-can-outline</v-icon
                  >
                  Delete package</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <!-- End of the datatable -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "InstitutionsTable",
  data: () => ({
    dialog: false,
    search: "",

    headers: [
      {
        text: "Package name",
        align: "start",
        sortable: false,
        value: "packageName",
      },
      { text: "Package category", value: "packageCategory" },
      { text: "Package Duration", value: "packageDuration" },
      { text: "Package Duration Type", value: "packageDurationType" },
      { text: "Package Storage", value: "packageStorage" },
      { text: "Package Storage Type", value: "packageStorageType" },
      { text: "Package Storage Type", value: "packageStorageType" },
      { text: "Number of collaborators", value: "packageNumberCollaborators" },
      { text: "Price", value: "packageAmount" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    packageUniqueId: "",
    packageName: "",
    packageCategory: "",
    packageNumberSurveys: "",
    packageNumberCollaborators: "",
    packageDuration: "",
    packageDurationType: "",
    packageStorage: "",
    packageStorageType: "",
    packageAmount: "",
    categories: ["private", "Institutional"],
    durationType: ["day", "week", "months", "year"],
    storageTypes: ["Mbs", "Gbs"],
  }),
  async mounted() {
    this.$store.dispatch("fectAllPackages");
  },
  computed: {
    ...mapGetters({
      packages: "getAllPackages",

      packageDetails: "getPackage",
    }),
  },

  methods: {
    deletePackage(packageId) {
      this.$store.dispatch("deletePackage", packageId);
    },

    takeId(packageId) {
      // console.log("The package id is", packageId);
      this.$store.dispatch("fetchPackage", packageId);
    },

    editPackage(packageDetails) {
      var data = {
        packageUniqueId: packageDetails.uniqueId,
        packageName: packageDetails.packageName,
        packageCategory: packageDetails.packageCategory,
        packageNumberSurveys: packageDetails.packageNumberSurveys,
        packageNumberCollaborators: packageDetails.packageNumberCollaborators,
        packageDuration: packageDetails.packageDuration,
        packageDurationType: packageDetails.packageDurationType,
        packageStorage: packageDetails.packageStorage,
        packageStorageType: packageDetails.packageStorageType,
        packageAmount: packageDetails.packageAmount,
        categories: packageDetails.categories,
        durationType: packageDetails.durationType,
        storageTypes: packageDetails.storageTypes,
      };

      this.$store.dispatch("editPackage", data);

      // console.log("The data to be sent ", data);

      this.dialog = false;
    },
  },
};
</script>
