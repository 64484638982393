import Vue from "vue";
import Vuex from "vuex";
// import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";

import authStore from "@/store/Auth-store/authStore";
import surveyStore from "@/store/survey-store/surveyStore";
import accessLevelStore from "@/store/accessLevelsStore/accessLevelStore.js";
import manageinstitutionsStore from "@/store/manageInstitutionsStore/manageInstitutionsStore.js";
import packageManagementStore from "@/store/packageManagementStore/packagemanagement.js";
import manageUsersStore from "@/store/manageUsers/manageUsersStore.js";
import landingPageStore from "@/store/landingPage/landingPageStore.js";
import systemManagementStore from "@/store/systemManagement/systemManagementStore.js";

// let ls = new SecureLS({
//   encodingType: "aes",
//   isCompression: false,
//   encryptionSecret: process.env.VUE_APP_STR_PWD,
// });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    namespaced: true,
    authStore,
    surveyStore,
    accessLevelStore,
    manageinstitutionsStore,
    packageManagementStore,
    manageUsersStore,
    landingPageStore,
    systemManagementStore
    
  },
  plugins: [createPersistedState({
    // key: "EG",
    // storage: {
    //   getItem: (key) => ls.get(key),
    //   setItem: (key, value) => ls.set(key, value),
    //   removeItem: (key) => ls.remove(key),
    // },
  })
]
});

