
<template>
  <div>
    <v-app class="background">
      <v-container class="container">
        <v-row>
          <v-col class="d-flex justify-center">
            <v-card
              class="mx-auto my-12"
              elevation="10"
              width="500"
              style="
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 0);
              "
            >
              <template slot="progress"> </template>
              <v-row class="avatarow">
                <v-col class="d-flex justify-center">
                  <v-avatar color="white" size="150">
                    <v-img
                      src="../../assets/images/tz.png"
                      width="150"
                      height="150"
                    />
                  </v-avatar>
                </v-col>
              </v-row>
              <v-card-text>
                <v-row align="center" class="mx-0">
                  <v-col>
                    <v-form>
                      <v-text-field
                        label="Enter reset email"
                        v-model="email"
                        type="email"
                        outlined
                        dense
                        prepend-inner-icon="mdi-email"
                      />

                      <v-btn
                        color="primary"
                        class="white--text btn-login btn"
                        block
                        style="text-transform: unset"
                        depressed
                        @click="handleResetEmailRequest"
                      >
                        Send
                      </v-btn>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-btn
                      depressed
                      color="transparent"
                      class="blue--text"
                      style="text-transform: unset"
                      to="/auth/login"
                      >Register</v-btn
                    >
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      depressed
                      color="transparent"
                      class="blue--text"
                      style="text-transform: unset"
                      to="/auth/login"
                      >Login</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "ForgotPasswordComponent",
  data: () => ({
    email: null
  }),

  methods:{
    handleResetEmailRequest(){

      let  data = {
        email: this.email
      }

      this.$store.dispatch("resetPasswordEmail", data)

      this.email = ""

    }
  }
};
</script>
<style scoped>
.background {
  background-image: url("../../assets/images/background1.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  margin-top: 5%;
}
.avatarow {
  margin-top: 3%;
}

</style>
