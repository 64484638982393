<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500">
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              color="primary"
              elevation="10"
              dark
              fab
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
            >
              <v-icon> mdi-package-variant-closed-plus</v-icon>
            </v-btn>
          </template>
          <span class="white--text">Add Package</span>
        </v-tooltip>
      </template>
      <v-card color="rgba(255,255,255,1)">
        <v-card-title class="text-h5 text-center">
          <span
            ><v-icon size="30" color="blue">
              mdi-package-variant-plus</v-icon
            ></span
          >
          <h5 class="text-center blue--text">Add Package</h5>
        </v-card-title>
        <!-- start of the form -->
        <v-card-text>
          <!-- Package Name -->
          <v-text-field
            label="Package Name"
            v-model="packageName"
            outlined
            dense
          />
          <!--  End Package Name -->
          <!-- Package Category -->
          <v-select
            :items="categories"
            label="Package category"
            dense
            v-model="packageCategory"
            outlined
          ></v-select>
          <!-- End of Package Category -->
          <!-- Number of Surveys -->
          <v-text-field
            label="Number of surveys"
            v-model="packageNumberSurveys"
            type="number"
            outlined
            dense
          />
          <v-text-field
            label="Number of colaborators"
            v-model="packageNumberCollaborators"
            type="number"
            outlined
            dense
          />
          <v-row>
            <v-col>
              <v-text-field
                label="Package duration"
                v-model="packageDuration"
                type="number"
                outlined
                dense
              />
            </v-col>

            <v-col>
              <v-select
                :items="durationType"
                label="Package duration type"
                dense
                v-model="packageDurationType"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Storage"
                v-model="packageStorage"
                type="number"
                outlined
                dense
              />
            </v-col>
            <v-col>
              <v-select
                :items="storageTypes"
                label="Package storage type"
                dense
                v-model="packageStorageType"
                outlined
              />
            </v-col>
          </v-row>
          <v-text-field
            label="Amount"
            v-model="packageAmount"
            type="number"
            outlined
            dense
          />
        </v-card-text>
        <div class="text-right mx-7">
          <v-btn color="red" class="mx-2 white--text" @click="dialog = false"> Cancel </v-btn>
          <v-btn color="primary" @click="createPackageHandler"> Add </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Button",
  data: () => ({
    dialog: false,
    categories: ["private", "Institutional"],
    durationType: ["day", "week", "months", "year"],
    storageTypes: ["Mbs", "Gbs"],

    packageName: "",
    packageAmount: "",
    packageStorage: "",
    packageDuration: "",
    packageCategory: "",
    packageNumberSurveys: "",
    packageNumberCollaborators: "",
    packageStorageType: "",
    packageDurationType: "",
  }),
  methods: {
    createPackageHandler() {
      var data = {
        packageName: this.packageName,
        packageAmount: this.packageAmount,
        packageStorage: this.packageStorage,
        packageDuration: this.packageDuration,
        packageCategory: this.packageCategory,
        packageNumberSurveys: this.packageNumberSurveys,
        packageNumberCollaborators: this.packageNumberCollaborators,
        packageStorageType: this.packageStorageType,
        packageDurationType: this.packageDurationType,
      };
      this.$store.dispatch("createPackage", data);
      this.dialog = false;
    },
  },
};
</script>
