<template>
  <v-app class="background">
    <Topnav />
    <Sidenav />
    <!-- <v-container fluid class="">
      <v-row class="">
        <v-col sm="12" md="6" lg="3">
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 5 : 2"
              :class="{ up: hover }"
              outlined
              color="rgba(255,255,255,0)"
              link
              to="/manage_institutions"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Institutions</div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ institutionsNumber }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar tile size="80" color="white">
                  <v-icon style="font-size: 72px" dark large color="#11662e"
                    >mdi-domain</v-icon
                  >
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-divider class="my-3" />
    </v-container> -->

    <v-container fluid style="margin-top:5%;">
      <v-row>
        <v-col>
          <v-dialog
            v-model="dialog"
            persistent
            width="500px"
            v-if="hasAccess('CAN_CREATE_INSTITUTION')"
          >
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-btn
                color="white"
                elevation="2"
                dark
                v-bind="attrs"
                v-on="{  ...dialog }"
              >
                <span class="primary--text"> ADD INSTITUTION </span>
              </v-btn>
            </template>
            <v-card elevation="5" color="rgba(255,255,255,1)">
              <v-card-title class="text-h5 text-center">
                <h5 class="text-center blue--text">Add Institution</h5>
              </v-card-title>

              <v-card-text>
                <v-text-field
                  label="Institution Name"
                  v-model="institutionName"
                  outlined
                  dense
                />

                <v-file-input
                  dense
                  label="Institution Logo"
                  placeholder="click here to upload the logo"
                  outlined
                  prepend-inner-icon=""
                  prepend-icon=""
                  v-model="institutionLogo"
                />

                <v-text-field
                  label="Institution short form"
                  v-model="institutionShortForm"
                  outlined
                  dense
                />

                <v-textarea
                  outlined
                  label="Institution description"
                  v-model="institutionDescription"
                  dense
                />
              </v-card-text>
              <v-card-actions class="mx-2">
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  class="white--text"
                  @click="dialog = false"
                  style="text-transform: unset"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  class="white--text"
                  @click="addInstitutionHandler"
                  style="text-transform: unset"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab" background-color="#f2f2f2">
          <v-tab @click="getAllInstitutions()" color="#f2f2f2">
            <h1 style="color: primary"><v-icon></v-icon>Institutions</h1>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card elevation="2">
              <v-card-title class="blue--text">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="institutions"
                :search="search"
                items-per-page=5
              >
                <template v-slot:[`item.SN`]="{ item }">
                  {{ institutions.indexOf(item) + 1 }}
                </template>
                <template v-slot:[`item.institutionLogo`]="{ item }">
                  <td v-if="item.institutionLogo">
                    <v-avatar size="56">
                      <img
                        :src=" fileroot + 'dodoso_contents/' +item.institutionLogo
                        "
                        style="width: 50px; height: 50px"
                      />
                    </v-avatar>
                  </td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu app offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        slot="activator"
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item link>
                        <v-list-item-title>
                          <span> </span>
                          <v-icon style="height: 16px; font-size: 16px" color="primary"
                            >mdi-archive-edit-outline</v-icon
                          >
                          <v-dialog v-model="dialog1" persistent width="500px">
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                color="white"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                depressed
                                style="text-transform: unset; color: black"
                                class="primary--text"
                                @click="takeId(item.uniqueId)"
                              >
                                Edit Institution
                              </span>
                            </template>
                            <v-card
                              color="rgba(255,255,255,1)"
                              v-if="institution"
                            >
                              <v-card-title class="text-h5 text-center">
                                <h5 class="text-center primary--text mx-2">
                                  Edit Institution
                                </h5>
                              </v-card-title>
                              <v-card-text>
                                <v-text-field
                                  label="Institution Name"
                                  v-model="institution.institutionName"
                                  outlined
                                  dense
                                  :value="institution.institutionName"
                                />
            

                                <v-file-input
                                  dense
                                  label="Institution Logo"
                                  placeholder="click here to upload the logo"
                                  outlined
                                  prepend-inner-icon=""
                                  prepend-icon=""
                                  v-model="institution.institutionLogo"
                          
                                />
                                <v-text-field
                                  label="Institution short form"
                                  v-model="institution.institutionShortForm"
                                  outlined
                                  :value="institution.institutionShortForm"
                                  dense
                                />
                                <v-textarea
                                  outlined
                                  label="Institution description"
                                  v-model="institution.institutionDescription"
                                  :value="institution.institutionDescription"
                                  dense
                                />
                              </v-card-text>
                              <v-card-text> </v-card-text>
                              <v-card-actions class="mx-3">
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="red"
                                  class="white--text"
                                  @click="dialog1 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  class="white--text"
                                  @click="editInstitution(institution)"
                                >
                                  save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog></v-list-item-title
                        ></v-list-item
                      >
                      <v-list-item
                        link
                        @click="deleteInstitution(item.uniqueId)"
                      >
                        <v-list-item-title class="red--text"
                          ><v-icon
                            style="height: 16px; font-size: 16px"
                            color="red"
                            >mdi-trash-can-outline</v-icon
                          >
                          Delete institution</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Topnav from "@/components/navigators/Topnav.vue";
import Sidenav from "@/components/navigators/Sidenav.vue";

import { mapGetters } from "vuex";
export default {
  name: "ManageInstitutionsView",
  components: {
    Topnav,
    Sidenav,
  },
  data: () => ({
    dialog: false,
    dialog1: false,
    institutionName: "",
    institutionLogo: "",
    institutionShortForm: "",
    institutionDescription: "",
    dialogDelete: false,
    fileroot: process.env.VUE_APP_FILES_ROOT,
    search: "",
    tab: "",
    headers: [
      {
        text: "Institution logo",
        align: "start",
        sortable: false,
        value: "institutionLogo",
      },
      { text: "Institution name", value: "institutionName" },
      { text: "Institution short form", value: "institutionShortForm" },
      { text: "Institution description", value: "institutionDescription" },
      { text: "Created by", value: "createdBy" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  methods: {
    addInstitutionHandler() {
      var data = {
        institutionName: this.institutionName,
        institutionShortForm: this.institutionShortForm,
        institutionLogo: this.institutionLogo,
        institutionDescription: this.institutionDescription,
      };

      this.$store.dispatch("addInstitution", data);

      this.institutionName = "";
      this.institutionShortForm = "";
      this.institutionLogo = "";
      this.institutionDescription = "";

      this.dialog = false;
    },
    getAllInstitutions() {
      this.$store.dispatch("fetchAllInstitutions");
    },
    deleteInstitution(uniqueId) {
      this.$store.dispatch("deleteInstitution", uniqueId);
    },
    takeId(uniqueId) {
      this.$store.dispatch("fetchInstitution", uniqueId);
    },
    editInstitution(institution) {
      var data = {
        uniqueId: institution.uniqueId,
        institutionName: institution.institutionName,
        institutionShortForm: institution.institutionShortForm,
        institutionLogo: institution.institutionLogo,
        institutionDescription: institution.institutionDescription,
      };
      this.$store.dispatch("editInstitution", data);
      this.dialog1 = false;
    },
  },

  computed: {
    ...mapGetters({
      permissions: "getUserPermissions",
      institutionsNumber: "getInstitutionsNumber",
      packageNumber: "getPackageNumber",
      userNumber: "getUserNumber",
      institutions: "getAllInstitutions",
      institution: "getInstitution",
    }),
  },
  async mounted() {
    this.$store.dispatch("fetchInstitutionsNumber");
    this.$store.dispatch("fetchAllInstitutions");
  },
};
</script>

<style scoped>
</style>