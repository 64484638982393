<template>
  <v-app class="background">
    <Topnav />
    <SurveysSidenav />

    <v-container style="margin-top: 5%">
      <v-row class="d-flex">
        <v-dialog v-model="dialog" persistent width="500">
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-btn
              color="white"
              elevation="2"
              class="mx-3 mb-3"
              dark
              v-bind="attrs"
              v-on="{ ...dialog }"
            >
              <span class="primary--text"> ADD CATEGORY </span>
            </v-btn>
          </template>
          <v-card color="rgba(255,255,255,1)">
            <v-card-title class="text-h5 text-center">
              <h5 class="text-center primary--text">Add survey category</h5>
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="category name"
                v-model="categoryName"
                outlined
                dense
              />
            </v-card-text>
            <v-card-actions class="mx-3">
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                class="white--text"
                @click="dialog = false"
                style="text-transform: unset"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                class="white--text"
                @click="addSurveyCategory()"
                style="text-transform: unset"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab" background-color="#f2f2f2">
          <v-tab @click="getSurveyCategories()">
            <h1 color="blue--text">
              <v-icon>mdi-shuriken</v-icon> Survey & quiz categories
            </h1>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card elevation="2">
              <v-card-title class="primary--text">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-card-title>
              <!-- {{surveyCategories}} -->
              <v-data-table
                :headers="headers"
                :items="surveyCategories"
                :search="search"
                items-per-page=5
              >
                <template v-slot:[`item.SN`]="{ item }">
                  {{ surveyCategories.indexOf(item) + 1 }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu app offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        slot="activator"
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item
                        link
                        @click="deleteSurveyCategory(item.categoryUniqueId)"
                      >
                        <v-list-item-title class="red--text"
                          ><v-icon
                            style="height: 16px; font-size: 16px"
                            color="red"
                            >mdi-trash-can-outline</v-icon
                          >
                          <span
                            style="text-transform: unset"
                            class="red--text mx-5"
                            >Delete category
                          </span></v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Topnav from "@/components/navigators/Topnav.vue";
import SurveysSidenav from "@/components/navigators/Sidenav.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Topnav,
    SurveysSidenav,
  },

  data() {
    return {
      dialog: false,
      categoryName: "",
      search: "",
      tab: "",
      headers: [
        {
          text: "SN",
          align: "start",
          sortable: false,
          value: "SN",
        },
        { text: "category name", value: "categoryName" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    addSurveyCategory() {
      this.$store.dispatch("addSurveyCategory", {
        categoryName: this.categoryName,
      });
      this.categoryName = "";
      this.dialog = false;
    },
    deleteSurveyCategory(categoryUid) {
      this.$store.dispatch("deleteSurveyCategory", {
        categoryUid: categoryUid,
      });
    },
    getSurveyCategories() {
      this.$store.dispatch("fetchAllSurveyCategories");
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchAllSurveyCategories");
  },
  computed: {
    ...mapGetters({
      surveyCategories: "getAllSurveyCategories",
    }),
  },
};
</script>

<style scoped>
.background {
  background-color: aliceblue;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
