<template>
  <div class="relative h-full">
    <div class="w-full h-full relative">
      <div class="w-full h-full absolute">
        <v-row>
          <v-col>
            <v-carousel
              cycle
              hide-delimiter-background
              hide-delimiters
              interval="5000"
              progress
              height="100vh"
              show-arrows-on-hover
              v-model="fade"
            >
              <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                reverse-transition="fade-transition"
              >
                <v-img :src="item.src" height="100vh" />
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </div>
      <div
        class="w-full h-full absolute bg-gradient-to-r from-fade-0 to-fade-75"
      ></div>
      <img
        class="absolute top-0 m-5"
        src="@/assets/images/tz.png"
        alt="Nembo ya taifa"
        width="100px"
      />
      <h1
        class="font-bold text-3xl top-0 absolute right-0 mr-5 mt-14 text-white"
      >
        eDodoso
      </h1>
      <!-- <div class="m-4 absolute bottom-0 flex flex-col">
        <div class="flex py-2 px-4 flex-row items-center rounded-lg bg-white  cursor-pointer hover:bg-blue-100">
          <span>
            <v-img src="@/assets/android.svg" class="icons-blue" alt="Android logo" width="35px" srcset="" />
          </span>
          <span class="ml-2">Download Android App</span>
        </div>

        <div class="flex mt-3 py-2 px-4 flex-row items-center rounded-lg bg-white  cursor-pointer hover:bg-blue-100">
          <span>
            <v-img src="@/assets/apple.svg" class="icons-blue" alt="iOS logo" width="35px" srcset="" />
          </span>
          <span class="ml-2">Download iOS App</span>
        </div>
      </div> -->
      <div
        class="top-0 right-0 w-1/3 h-full flex flex-col justify-center absolute"
      >
        <div
          class="rounded-l-md border-l-4 border-blue-500 shadow-black shadow-sm"
        >
          <div class="flex flex-row items-center bg-blue-400 p-2">
            <v-btn icon color="white">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <span class="font-bold text-white">Actions</span>
          </div>
          <div class="p-4 card-sharp">
            <div class="flex flex-row justify-end">
              <v-btn
                class="mx-1 white--text"
                style="text-transform: unset"
                color="primary"
                to="auth/login"
                @click="goToRegister()"
              >
                <span>
                  <v-icon>mdi-account-plus-outline</v-icon>
                </span>
                <span>Register</span>
              </v-btn>

              <v-btn
                class="mx-1 white--text"
                style="text-transform: unset"
                color="primary"
                to="auth/login"
                @click="goToLogin()"
              >
                <span>
                  <v-icon>mdi-login</v-icon>
                </span>
                <span>Login</span>
              </v-btn>
            </div>

            <hr class="mt-4" />
            <div class="text-blue-500 my-1 text-sm font-bold">Surveys</div>
            <div class="">
              <form>
                <div class="flex flex-row items-center">
                  <v-text-field
                    hide-details
                    dense
                    outlined
                    placeholder="Enter Survey number"
                    v-model="surveyNumber"
                    class="mr-2 pa-0 ma-0"
                  />
                  <v-btn color="primary" depressed @click="fetchSurveyByNumber">
                    <v-icon left>mdi-open-in-new</v-icon>
                    Open
                  </v-btn>
                </div>
              </form>
            </div>
            <div class="pt-4 text-center">
              <v-btn
                v-on:click="openPublicSurveys"
                color="primary"
                style="text-transform: unset"
              >
                View public surveys
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LandingPageComponent",

  components: {},
  data: () => ({
    items: [
      {
        src: "https://www.ega.go.tz/uploads/gallery/16123329025cfb09bcbd423c442db28d57feba10e2.png",
      },
      {
        src: "https://www.ega.go.tz/uploads/gallery/1595247956e01b7a4534f70e97452da1e26bfbff9d.png",
      },
      // {
      //   src: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.ega.go.tz%2F&psig=AOvVaw1gGCtBQHaZSX7x_zbA1uMY&ust=1664985759196000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCNCOg8D5xvoCFQAAAAAdAAAAABAD"
      // }
    ],
    selected: [],
    benched: 0,
    surveyNumber: "",
    fade: null,
  }),

  async mounted() {
    await this.$store.dispatch("fetchPublicSurveys");
  },
  computed: {
    ...mapGetters({
      publicSurveys: "getPublicSurveys",

      isAuthenticated: "isLoggedIn",

      surveyCategories: "getAllSurveyCategories",

      privateSurveyId: "getPrivateSurveyId",
    }),
  },
  methods: {
    openPublicSurveys() {
      this.$router.push("/public-surveys");
    },
    logoutHandler() {
      localStorage.removeItem("apollo-token");

      this.$store.commit("REMOVE_LOGGED_USER");

      this.$router.push("/home");
    },
    async fetchSurveyByNumber() {
      if (this.surveyNumber != "") {
        await this.$store.dispatch("fetchSurveyByNumber", this.surveyNumber);
        if (this.privateSurveyId) {
          this.$router.push("/response/" + this.privateSurveyId);
        } else {
          this.$router.push("/");
        }
      }
    },
    goToLogin() {
      this.$store.commit("setStep", 2);
      this.$router.push("auth/login");
    },
    goToRegister() {
      this.$store.commit("setStep", 1);
      this.$router.push("auth/login");
    },
  },
};
</script>
<style scoped>
.background {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
</style>
