import Quizes from "@/views/quizes/Quizes.vue"
import QuizesList from "@/views/quizes/QuizesList.vue"
import PreviewQuizAnswers from "@/views/quizes/PreviewQuizAnswers.vue";

const quizesRouter = {
  path: "/quizes",
  name: "Quizes",
  component: Quizes,
  children: [
    {
      path: "quizesList",
      name: "QuizesList",
      component: QuizesList,
    },
    {
      path: "preview_quiz_answers/:quizId",
      name: "PreviewQuizAnswers",
      component: PreviewQuizAnswers,
    }
  ],
};


export default quizesRouter;
