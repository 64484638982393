<template>
  <v-app>
    <v-main style="background-image('@/assets/background.png')">
      <router-view />

    </v-main>
    <!-- <v-footer color="rgba(0,0,255,.1)" >
      <v-row>
        <v-col class="text-center">
          <div>
            <h6 class="caption">
              <span>&copy; {{ new Date().getFullYear()}} | </span> Designed & Developed by <strong class="primary--text">eGA</strong>
            </h6>
          </div>
        </v-col>
      </v-row>

    </v-footer> -->

  </v-app>
</template>
<style >
#app{
  background-color: white;
  background-size: cover;
}
</style>
<script>
require("regenerator-runtime");
export default {
  name: "App",
};
</script>
