<template>
  <div>
    <v-app class="background">
      <v-container style="margin-top:25vh" fluid>
        <v-row align="center" justify="center">
          <v-col cols="8" sm="6" md="6">
            <v-card
              :loading="loading"
              class="mx-auto rounded-xl v-card v-sheet theme--light"
              elevation="5"
            >
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row class="fill-height">
                    <v-col cols="12" md="8">
                      <v-card-text class="">
                        <div class="text-center mb-7">
                          <v-avatar
                            color="white"
                            size="130"
                            class="emblem-style"
                          >
                            <v-img
                              src="@/assets/images/tz.png"
                              width="150"
                              height="150"
                            />
                          </v-avatar>
                        </div>
                        <div class="text-center mb-4"></div>
                        <h4 class="text-center mt-4">
                          Reset Password
                        </h4>
                        <v-form>
                          <v-text-field
                            label="Enter password"
                            outlined
                            v-model="password1"
                            type="password"
                            dense
                            prepend-inner-icon="mdi-lock"
                          ></v-text-field>
                          <v-text-field
                            label="Re-enter password"
                            outlined
                            type="password"
                            dense
                            v-model="password2"
                            prepend-inner-icon="mdi-lock"
                          ></v-text-field>
                        </v-form>
                      </v-card-text>
                      <div class="text-center mt-n5">
                        <v-btn
                          color="primary"
                          class="mr-4 my-3"
      
                          @click="handleResetPassword()"
                          rounded
                          style="text-transform: none; color: white"
                        >
                          Send
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12" md="4" class="primary"> </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
export default {
  data: () => ({
    password1: null,
    password2: null,
    loading: false,
    step:1
  }),

  methods: {
    handleResetPassword() {
      let token = this.$route.params.token;
      
      let data = {
        token: token,
        newPassword1: this.password1,
        newPassword2: this.password2,
      };

      this.$store.dispatch("resetPassword", data);

      this.password1 = "",
      this.password2 = ""
      
    },
  },
};
</script>
<style scoped>
.background {
  background-image: url("../../assets/images/background1.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  margin-top: 5%;
}
.avatarow {
  margin-top: 3%;
}
.btn {
  text-transform: unset;
}
a {
  text-decoration: none;
}
</style>
