<template>
    <div class="hover:bg-fade-5 cursor-pointer" @click="navigateTo">
        <hr class="hr-white">
        <div class="flex flex-row py-2">
            <v-icon color="blue" size="35">{{ icon }}</v-icon>
            <div class="flex flex-col ml-4 justify-center">
                <h2 class="font-bold">
                    {{ title }}
                </h2>
                <span class="text-sm text-fade-75" v-if="showSubtitle">
                    {{ subtitle }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ListItem",
    props: ["title", "icon", "subtitle", "showSubtitle",
        "link", "to"],
    methods: {
        navigateTo() {
            this.$router.push(this.to);
        },
    }
}
</script>
<style>
</style>