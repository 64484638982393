import SystemSettings from "@/views/systemSettings/SystemSettings.vue";
import AccessLevel from "@/views/systemSettings/AccessLevelView.vue";
import UsersAssignedToRole from "@/views/systemSettings/UsersAssignedToRoleView.vue";

const systemSettingsRouter = {
  path: "/system_settings",
  name: "SystemSettings",
  component: SystemSettings,
  children: [
    {
      path: "access_levels",
      name: "AccessLevel",
      component: AccessLevel
    },
    {
      path:'users_assigned_to_role/:roleUniqueId',
      name: 'UsersAssignedToRole',
      component: UsersAssignedToRole
    }
  ],
};

export default systemSettingsRouter;
