<template>
  <v-container fluid class="mt-2">
    <v-row class="mx-1">
      <v-col sm="12" md="6" lg="4" :key="i">
        <v-card elevation="10" size="" color="rgba(255,255,255,0)">
          <v-row class="my-1">
            <v-col cols="6" class="text-center">
              <v-row>
                <v-col>
                  <h3 class="primary--text">All Packages</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h1 class="text-h3 font-weigth-bold">10</h1>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-avatar size="120" tile>
                <v-img
                height="100" width="100"
                  src="../../../assets/images/institutionPackage.png"
                  class="filter-blue"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="12" md="6" lg="4" :key="i">
        <v-card elevation="10" size="" color="rgba(255,255,255,0)">
          <v-row class="my-1">
            <v-col cols="6" class="text-center">
              <v-row>
                <v-col>
                  <h3 class="primary--text">Private Packages</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h1 class="text-h3 font-weigth-bold">2</h1>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-avatar size="120" tile>
                <v-img
                  src="../../../assets/images/institutionPackage.png"
                  class="filter-blue"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="12" md="6" lg="4" :key="i">
        <v-card elevation="10" size="" color="rgba(255,255,255,0)">
          <v-row class="my-1">
            <v-col cols="6" class="text-center">
              <v-row>
                <v-col>
                  <h3 class="primary--text">Private Packages</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h1 class="text-h3 font-weigth-bold">2</h1>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-avatar size="120" tile>
                <v-img
                  src="../../../assets/images/institutionPackage.png"
                  class="filter-blue"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

// const packagesNumber = this.packages.length

export default {
  name: "TopCardsComponent",
  data: () => ({
    cards: [
      {
        count: 30,
        text: "Total Packages",
        icon: "mdi-package-variant-closed",
        color: "blue",
        countClass: "blue--text text-center font-weight-bold  text-h3",
        textClass: "blue--text text-center text-uppercase  ",
      },
      {
        count: 2,
        text: "Premium Packages",
        icon: "mdi-gift",
        color: "blue",
        countClass: "blue--text text-center font-weight-bold  text-h3",
        textClass: "blue--text text-center text-uppercase  ",
      },

      {
        count: 3,
        text: "Free Packages",
        icon: "mdi-gift-open",
        color: "blue",
        countClass: "blue--text text-center font-weight-bold text-h3",
        textClass: "blue--text text-center text-uppercase  ",
      },
    ],
  }),

  async mounted() {
    await this.$store.dispatch("fectAllPackages");
  },

  computed: {
    ...mapGetters({
      packages: "getAllPackages",
      allPackagesCount: "getPackagesNumber",
    }),
  },
};
</script>
<style scoped>
.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
}
.filter-blue {
  filter: invert(33%) sepia(100%) saturate(3814%) hue-rotate(192deg)
    brightness(93%) contrast(102%);
}
</style>