<template>
  <div class="container">
    <div id="tableContainer"></div>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
import { DataTables } from "survey-analytics/survey.analytics.datatables";
import { json, data } from "./analytics_data";

import $ from "jquery";
import "datatables.net/js/jquery.dataTables.js";
import "datatables.net-dt/js/dataTables.dataTables.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-colreorder/js/dataTables.colReorder.js";
import "datatables.net-rowgroup/js/dataTables.rowGroup.js";

import "survey-analytics/survey.analytics.datatables.css";
import "datatables.net-colreorder-dt/css/colReorder.dataTables.css";
import { mapGetters } from "vuex";

/* eslint-disable vue/no-unused-components */

const filesRoot = process.env.VUE_APP_FILES_ROOT;

export default {
  components: {
    SurveyVue,
  },

  data() {
    const survey = new SurveyVue.Model(json);
    return { survey, data };
  },

  async mounted() {
    this.isLoading = true;
    let surveyId = this.$route.params.surveyId;

    await this.$store.dispatch("loadSurveyPayload", surveyId);

    await this.$store.dispatch("fetchSurveyByUid", surveyId);

    let surveyContent = await this.fetchSurveyPayload(
      filesRoot + this.$store.getters.getSurveyPayload
    );

    DataTables.initJQuery($);
    // var surveyAnalyticsDataTables = new DataTables(this.survey, this.data);
    // surveyAnalyticsDataTables.render(document.getElementById("tableContainer"));

    console.log("The data found in console", this.surveyAnswers);

    var surveyAnalyticsDataTables = new DataTables(
      surveyContent,
      this.surveyAnswers
    );
    surveyAnalyticsDataTables.render(document.getElementById("tableContainer"));
  },

  computed: {
    ...mapGetters({
      surveyAnswers: "getSurveyAnswers",
    }),
  },

  methods: {
    async fetchSurveyPayload(url) {
      let response = await fetch(url);
      let data = response.text();

      return data;
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
