<template>
  <v-app class="background">
    <Topnav />
    <Sidenav />
    <v-container fluid class="">
      <v-row class="">
        <v-col sm="12" md="6" lg="4" v-for="item in cards" :key="item.title">
          <v-hover v-slot="{ hover }" v-if="hasAccess(item.permission)">
            <v-card
              :elevation="hover ? 5 : 2"
              :class="{ up: hover }"
              outlined
              link
              color="rgba(255,255,255,0)"
              to="#"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4 display-1">
                    {{ item.title }}
                  </div>
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'TOTAL USERS'"
                    >{{ userManagementAnalysis.totalUsers }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'ACTIVE USERS'"
                    >{{ userManagementAnalysis.activeUsers }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'INACTIVE USERS'"
                    >{{
                      userManagementAnalysis.inactiveUsers
                    }}</v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-avatar tile size="80" color="white">
                  <v-icon style="font-size: 72px" dark large color="#db9b32"
                    >mdi-account-multiple</v-icon
                  >
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
    </v-container>
    <!--- eND OF THE TOP CARDS ------->
    <v-container fluid>
      <v-row>
        <v-col>
          <span>
            <RegisterAdminButton v-if="hasAccess('CAN_CREATE_ADMIN')" />
          </span>
          <span class="mx-2">
            <v-dialog
              v-if="hasAccess('CAN_CREATE_INSTITUTION_USER')"
              v-model="dialog3"
              persistent
              width="500"
              color="white"
            >
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-btn
                  color="white"
                  elevation="2"
                  dark
                  v-bind="attrs"
                  v-on="{ ...dialog }"
                >
                  <span class="primary--text"> UPLOAD USERS EXCELL </span>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <div class="text-center">
                    <h3 class="text-center primary--text">
                      Upload users excel
                    </h3>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-file-input
                    label="File input"
                    outlined
                    dense
                    v-model="usersExcel"
                  />
                </v-card-text>
                <div class="text-right">
                  <v-btn
                    color="white"
                    class="primary--text mx-3"
                    @click="dowloadExcelHandler()"
                    style="text-transform: unset"
                  >
                    Downlod user excel template
                  </v-btn>
                  <v-btn
                    color="red"
                    class="white--text mx-3"
                    @click="dialog3 = false"
                    style="text-transform: unset"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="uplodUsersExcel()"
                    style="text-transform: unset"
                  >
                    Upload
                  </v-btn>
                </div>
                <v-card-actions class="text-right"> </v-card-actions>
              </v-card>
            </v-dialog>
          </span>
          <span>
            <v-dialog
              v-if="hasAccess('CAN_CREATE_INSTITUTION_USER')"
              v-model="dialog"
              persistent
              width="500"
              color="rgba(255,255,255,0"
            >
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-btn
                  color="white"
                  elevation="2"
                  dark
                  v-bind="attrs"
                  v-on="{ ...dialog }"
                >
                  <span class="primary--text"> ADD USER </span>
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <div class="text-center">
                    <h3 class="text-center primary--text">Register User</h3>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-form class="my-3 mx-1">
                    <v-text-field
                      outlined
                      dense
                      label="Enter Firstname"
                      type="text"
                      v-model="firstName"
                    />
                    <v-text-field
                      outlined
                      dense
                      label="Enter Lastname"
                      type="text"
                      v-model="lastName"
                    />
                    <v-text-field
                      outlined
                      dense
                      label="Enter username"
                      type="text"
                      v-model="username"
                    />
                    <v-text-field
                      outlined
                      dense
                      label="Enter email"
                      type="email"
                      v-model="email"
                    />
                    <v-text-field
                      v-model="password"
                      outlined
                      dense
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show ? 'text' : 'password'"
                      label="Enter Password"
                      hint="At least 8 characters"
                      @click:append="show = !show"
                    />
                  </v-form>
                </v-card-text>
                <div class="text-right mx-7">
                  <v-btn
                    color="red"
                    class="white--text mx-3"
                    @click="dialog = false"
                    style="text-transform: unset"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="createInstitutionUser"
                    style="text-transform: unset"
                  >
                    Register
                  </v-btn>
                </div>
                <v-card-actions class="text-right"> </v-card-actions>
              </v-card>
            </v-dialog>
          </span>
        </v-col>
        <v-col class="d-flex" cols="4" sm="4" v-if="selectorVissible">
          <v-select
            :items="this.$store.getters.getAllInstitutions"
            label="Fetch users by institution"
            outlined
            v-model="institution"
            item-text="institutionName"
            item-value="uniqueId"
            @change="getUsersByInstitution()"
            dense
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab" background-color="#f2f2f2">
          <v-tab @click="getActiveUsers()" style="color: #11662e">
            Active users
          </v-tab>
          <v-tab @click="getInactiveUsers()" style="color: #db9b32">
            Inactive users
          </v-tab>
          <v-tab @click="getAdmins()" v-if="hasAccess('CAN_VIEW_ADMIN')">
            ADMINSTRATORS
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" color="blue">
          <v-tab-item>
            <ActiveUsers />
          </v-tab-item>
          <!------------ END OF THE ACTIVE USERS -------->
          <v-tab-item>
            <InactiveUsers />
          </v-tab-item>
          <!-------- ADMINS TABLE ---->
          <v-tab-item>
            <Adminstrators v-if="hasAccess('CAN_VIEW_ADMIN')" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import Topnav from "@/components/navigators/Topnav.vue";
import Sidenav from "@/components/navigators/Sidenav.vue";
import RegisterAdminButton from "@/components/systemAdmin/manageUsers/RegisterAdminButton.vue";
import ActiveUsers from "@/components/manageusers/ActiveUsers.vue";
import InactiveUsers from "@/components/manageusers/InactiveUsers.vue";
import Adminstrators from "@/components/manageusers/Administrators.vue";

import { mapGetters } from "vuex";
export default {
  name: "Manageusers",
  components: {
    Topnav,
    Sidenav,
    RegisterAdminButton,
    ActiveUsers,
    InactiveUsers,
    Adminstrators,
  },
  data: () => ({
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    institution: "",
    institutions: [],
    usersExcel: "",
    filesRoot: process.env.VUE_APP_FILES_ROOT,
    tab: null,
    dialog: false,
    dialog1: false,
    dialog2: false,
    dialog3: false,
    dialog4: false,
    mypass: "",
    search: "",
    userStatus: "",
    value: String,
    uniqueId: "",
    isAdmin: false,
    headers: [
      {
        text: "First name",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      { text: "Last name", value: "lastName" },
      { text: "username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Institution", value: "institutionName" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    cards: [
      {
        title: "TOTAL USERS",
        icon: "mdi-account-multiple",
        color: "#db9b32",
        permission: "CAN_VIEW_USER",
      },
      {
        title: "ACTIVE USERS",
        icon: "mdi-account-multiple-check",
        color: "#11662e",
        permission: "CAN_VIEW_USER",
      },
      {
        title: "INACTIVE USERS",
        icon: "mdi-book-edit",
        color: "#1a568a",
        permission: "CAN_VIEW_USER",
      },
      // {
      //   title: "INSTITUTION USERS",
      //   icon: "mdi-book-edit",
      //   color: "#1a568a",
      //   permission:"CAN_VIEW_SYSTEM_ANALYSIS_SUMMARY"

      // },
      // {
      //   title: "PRIVATE USERS",
      //   icon: "mdi-book-edit",
      //   color: "#1a568a",
      //   permission:"CAN_VIEW_SYSTEM_ANALYSIS_SUMMARY"

      // },
    ],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    show: false,
  }),

  methods: {
    createInstitutionUser() {
      const user = {
        firstName: this.firstName,
        lastName: this.lastName,
        username: this.username,
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch("createInstUser", user);

      this.dialog = false;
    },
    uplodUsersExcel() {
      var data = {
        usersExcel: this.usersExcel,
      };
      this.$store.dispatch("createUsersByUploadingExcel", data);
    },
    getActiveUsers() {
      this.userStatus = "ACTIVE";
      this.selectorVissible = true;
      const data = {
        userStatus: "ACTIVE",
      };
      this.$store.dispatch("fetctActiveUsers", data);
    },
    getInactiveUsers() {
      this.userStatus = "INACTIVE";
      this.selectorVissible = true;
      const data = {
        userStatus: "INACTIVE",
      };
      this.$store.dispatch("fetctInactiveUsers", data);
    },
    registerAdminHandler() {
      let data = {
        institution: this.institution,
        user: parseInt(this.adminId),
      };
      this.$store.dispatch("assignUserToInstitution", data);
    },

    getAdmins() {
      this.selectorVissible = false;
      this.$store.dispatch("fetchInstitutionAdmins");
    },
    getUsersByInstitution() {
      const data = {
        userStatus: this.userStatus,
        institutionUniqueId: this.institution,
      };
      if (data.userStatus == "ACTIVE") {
        this.$store.dispatch("fetctActiveUsers", data);
      } else if (data.userStatus == "INACTIVE") {
        const data = {
          userStatus: this.userStatus,
          institutionUniqueId: this.institution,
        };
        this.$store.dispatch("fetctInactiveUsers", data);
      }
      // console.log("The data to be sent", data);
    },

    dowloadExcelHandler() {
      this.downloadFile(this.filesRoot, "dodoso_contents/templates/users.xlsx");
    },

    downloadFile(url, fileName) {
      fetch(url, {
        method: "get",
        mode: "no-cors",
        referrerPolicy: "no-referrer",
      })
        .then((res) => res.blob())
        .then((res) => {
          const aElement = document.createElement("a");
          aElement.setAttribute("download", fileName);
          const href = URL.createObjectURL(res);
          aElement.href = href;
          aElement.setAttribute("target", "_blank");
          aElement.click();
          URL.revokeObjectURL(href);
        });
    },
  },
  computed: {
    ...mapGetters({
      institutionsNumber: "getInstitutionsNumber",
      packageNumber: "getPackageNumber",
      userNumber: "getUserNumber",
      user: "getCreatedUser",
      adminId: "getCurrentRegistredAdminId",
      permissions: "getUserPermissions",
      adminsNumber: "getAdminsNumber",
      institutionUsersNumber: "getInstitutionUsersNumber",
      userManagementAnalysis: "getUserManagementAnalysis",
      roles: "getRoles",
    }),
  },
  async mounted() {
    await this.$store.dispatch("fetchUserManagementAnalysis");
    await this.$store.dispatch("fetchInstitutionAdmins");
    await this.$store.dispatch("fetctActiveUsers", {userStatus: "ACTIVE",});
    await this.$store.dispatch("fetctInactiveUsers", {userStatus: "INACTIVE",});
    await this.$store.dispatch("fetchRoleInMyInstitution");
    await this.$store.dispatch("fetchAllInstitutions");
  },
};
</script>

<style scoped>
.taby {
  text-transform: unset;
}
.background {
  background-color: aliceblue;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.filter-blue {
  filter: invert(33%) sepia(100%) saturate(3814%) hue-rotate(192deg)
    brightness(93%) contrast(102%);
}
</style>