<template>
  <v-navigation-drawer
    :mini-variant.sync="this.$store.getters.getDrawer"
    :expand-on-hover="onHover"
    permanent
    app
  >
    <v-img   src="../../assets/images/eDodoso_logo.png" class="m-1">
      <span class=""> </span>
      <div class="text-center">
        <!-- <h1 class="white--text display-1 font-weight-bold">edodoso</h1> -->
      </div>
    </v-img>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item class="listItem" link to="/dashboard">
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-microsoft-windows</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/surveys">
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Survey management</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/quizes/quizesList">
        <v-list-item-icon>
          <v-icon>mdi-head-flash-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Quiz management</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/survey_category_management">
        <v-list-item-icon>
          <v-icon>mdi-at</v-icon>
        </v-list-item-icon>
        <v-list-item-title>survey & quiz categories</v-list-item-title>
      </v-list-item>
      <v-list-item
        class="listItem"
        link
        to="/manage_institutions"
        v-if="hasAccess('CAN_MANAGE_INSTITUTION')"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-home-city</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Manage institutions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        class="listItem"
        link
        to="/manage_users"
        v-if="hasAccess('CAN_MANAGE_USER')"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-account-group</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Manage users</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        class="listItem"
        link
        to="/system_settings/access_levels"
        v-if="hasAccess('CAN_MANAGE_ACCESS_LEVEL')"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-shield-lock</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Access levels</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
          <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn block>
            Logout
          </v-btn>
        </div>
      </template> -->
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
  data() {
    return {
      links: [
        ["mdi-microsoft-windows", "Dashboard"],
        ["mdi-account", "Profile"],
        ["mdi-clipboard-list-outline", "Products"],
        ["mdi-card-account-details-outline", "Orders"],
        ["mdi-cog", "System Setting"]
      ],
      mini: null,
      onHover: null,
    };
  },
  computed: {
    ...mapGetters({
      drawer: "getDrawer",
    }),
  },
};
</script>

