<template>
  <v-app class="background">
    <Topnav />
    <Sidenav />
    <v-container fluid class="my-5">
      <v-row class="mx-">
        <v-col
          sm="12"
          md="4"
          lg="3"
          v-for="item in system_cards"
          :key="item.title"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 5 : 2"
              :class="{ up: hover }"
              outlined
              link
              color="rgba(255,255,255,0)"
              :to="item.link"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">{{ item.title }}</div>
                  <v-list-item-title
                    v-if="item.title == 'SURVEYS'"
                    class="text-h5 mb-1"
                    >{{ surveyNumber }}</v-list-item-title
                  >
                  <v-list-item-title
                    v-else-if="item.title == 'INSTITUTIONS'"
                    class="text-h5 mb-1"
                    >{{ institutionsNumber }}</v-list-item-title
                  >
                  <v-list-item-title
                    v-else-if="item.title == 'PACKAGES'"
                    class="text-h5 mb-1"
                    >{{ packageNumber }}</v-list-item-title
                  >
                  <v-list-item-title
                    v-else-if="item.title == 'USERS'"
                    class="text-h5 mb-1"
                    >{{ userNumber }}</v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-avatar tile size="80" color="white">
                  <v-icon
                    style="font-size: 72px"
                    dark
                    large
                    :color="item.color"
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <!-------->
      <v-divider class="my-3"></v-divider>
      <v-row style="margin-top: 2%">
        <v-col  sm="12" md="12" lg="4">
          <v-card>
            <apexchart
              width="100%"
              type="bar"
              :options="options"
              :series="series"
            />
          </v-card>
        </v-col>
        <v-col sm="12" md="12" lg="4">
          <v-card>
            <apexchart
              width="100%"
              type="bar"
              :options="options"
              :series="series"
            />
          </v-card>
        </v-col>
        <v-col sm="12" md="12" lg="4">
          <v-card>
            <apexchart
              type="pie"
              width="100%"
              :options="chartOptions"
              :series="[
                this.$store.getters.getSurveyNumber,
                this.$store.getters.getQuizNumber,
              ]"
            />
          </v-card>
        </v-col>
      </v-row>
      <div></div>
    </v-container>
  </v-app>
</template>

<script>
import Topnav from "@/components/navigators/Topnav.vue";
import Sidenav from "@/components/navigators/Sidenav.vue";

import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    Topnav,
    Sidenav,
  },

  data: () => ({
    system_cards: [
      {
        title: "SURVEYS",
        link: "/surveys",
        icon: "mdi-ballot",
        color: "#db9b32",
      },
      {
        title: "INSTITUTIONS",
        link: "/manage_institutions",

        icon: "mdi-domain",
        color: "#11662e",
      },
      {
        title: "PACKAGES",
        link: "",

        icon: "mdi-package",
        color: "#1a568a",
      },
      {
        title: "USERS",
        link: "/manage_users",

        icon: "mdi-account-group",
        color: "#db9b32",
      },
    ],
    // institution_cards: [
    //   {
    //     title: "INSTITUTION SURVEYS",
    //     link: "/surveys",
    //     icon: "mdi-ballot",
    //     color: "#db9b32",
    //   },
    //   {
    //     title: "INSTITUTION QUIZES",
    //     link: "/surveys",
    //     icon: "mdi-ballot",
    //     color: "#db9b32",
    //   },
    //   {
    //     title: "INSTITUTION PACKAGES",
    //     link: "",

    //     icon: "mdi-package",
    //     color: "#1a568a",
    //   },
    //   {
    //     title: "INSTITUTION USERS",
    //     link: "/manage_users",

    //     icon: "mdi-account-group",
    //     color: "#db9b32",
    //   },
    // ],
    options: {
      title: {
        text: "hello",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "24px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#448ee4",
        },
      },
      chart: {
        id: "vuechart-example",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
    chartOptions: {
      title: {
        text: "Surveys Vs. Quizes",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#448ee4",
        },
      },
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Surveys", "Quiz"],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  }),

  methods: {},

  computed: {
    ...mapGetters({
      permissions: "getUserPermissions",
      institutionsNumber: "getInstitutionNumber",
      packageNumber: "getPackageNumber",
      userNumber: "getUserNumber",
      surveyNumber: "getSurveyNumber",
      quizNumber: "getQuizNumber",
    }),
  },
  async mounted() {
    await this.$store.dispatch("fetchSystemSummary");
  },
};
</script>

<style scoped>
</style>