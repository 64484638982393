<template>
  <v-card elevation="10">
    <v-card-title class="blue--text">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="institutionAdmins"
      :search="search"
      items-per-page="5"
    >
      <template v-slot:item.actions="{ item }">
        <v-menu app offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              slot="activator"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item link>
              <v-list-item-title
                ><v-icon style="height: 16px; font-size: 16px" color="primary"
                  >mdi-account-tie</v-icon
                >
                <v-dialog v-model="dialog1" persistent max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text mx-5"
                      style="text-transform: unset"
                    >
                      Profile
                    </span>
                  </template>

                  <v-card
                    class="mx-auto"
                    max-width="500"
                    style="border-radius: 5px"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <div class="text-center">
                            <v-avatar color="" size="100">
                              <v-img
                                src="../../assets/images/user2.svg"
                                width="100"
                                height="100"
                                class="filter-blue"
                              />
                            </v-avatar>
                          </div>
                          <v-divider class="my-5"></v-divider>
                          <div>
                            <v-row>
                              <v-col cols="6">
                                <span class="font-weight-bold">First name:</span
                                ><span class="ml-3">{{ item.firstName }}</span>
                              </v-col>
                              <v-col cols="6">
                                <span class="font-weight-bold">Last name:</span
                                ><span class="ml-3">{{ item.lastName }}</span>
                              </v-col>
                              <v-col cols="6">
                                <span class="font-weight-bold">Username:</span
                                ><span class="ml-3">{{ item.username }}</span>
                              </v-col>
                              <v-col cols="6">
                                <span class="font-weight-bold">Email:</span
                                ><span class="ml-3">{{ item.email }}</span>
                              </v-col>

                              <v-col cols="12" v-if="item.institutionName">
                                <span class="font-weight-bold"
                                  >Institution:</span
                                ><span class="ml-3">{{
                                  item.institutionName
                                }}</span>
                              </v-col>
                            </v-row>
                          </div>
                          <v-divider class="my-3"></v-divider>
                          <div class="text-right">
                            <v-row>
                              <v-col>
                                <v-btn
                                  color="red"
                                  class="white--text"
                                  style="text-transform: unset"
                                  @click="dialog1 = false"
                                  >Close</v-btn
                                >
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-list-item-title>
            </v-list-item>

            <!--- edit profile ----->
            <v-list-item link>
              <v-list-item-title
                ><v-icon style="height: 16px; font-size: 16px" color="yellow"
                  >mdi-account-tie</v-icon
                >
                <v-dialog v-model="dialog2" persistent max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="yellow--text mx-5"
                      style="text-transform: unset"
                    >
                      edit profile
                    </span>
                  </template>

                  <v-card color="rgba(255,255,255,1)">
                    <v-card-title class="primary--text mb-3">
                      Edit Profile
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        label="First Name"
                        v-model="item.firstName"
                        :value="item.firstName"
                        outlined
                        dense
                      />

                      <v-text-field
                        label="Last Name"
                        v-model="item.lastName"
                        :value="item.lastName"
                        outlined
                        dense
                      />
                      <v-text-field
                        outlined
                        label="Username"
                        dense
                        v-model="item.username"
                        :value="item.username"
                      />
                      <v-text-field
                        outlined
                        label="Email"
                        dense
                        v-model="item.email"
                        :value="item.email"
                      />
                    </v-card-text>

                    <v-card-text> </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        style="text-transform: unset"
                        color="red"
                        class="white--text"
                        @click="dialog2 = false"
                      >
                        Cancel
                      </v-btn>

                      <v-btn
                        style="text-transform: unset"
                        color="primary"
                        @click="editProfile(item)"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-list-item-title>
            </v-list-item>
            <!--- end edit profile -->

            <v-list-item link @click="deleteUser(item.pk)">
              <v-list-item-title class="red--text"
                ><v-icon style="height: 16px; font-size: 16px" color="red"
                  >mdi-account-remove
                </v-icon>
                <span style="text-transform: unset" class="red--text mx-5"
                  >Delete admin
                </span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Adminstrators",
  data: () => ({
    search: "",
    headers: [
      {
        text: "First name",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      { text: "Last name", value: "lastName" },
      { text: "username", value: "username" },
      { text: "Email", value: "email" },
      { text: "Institution", value: "institutionName" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    dialog: "",
    dialog1: "",
    dialog2: "",
  }),
  methods: {
    editProfile(user) {
      var data = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        primaryKey: user.pk,
        username: user.username,
      };

      this.$store.dispatch("editUserDetails", data);
      this.dialog2 = false;
    },
    deleteUser(userId) {
      let data = {
        primaryKey: parseInt(userId),
        userStatus: "INACTIVE",
      };

      this.$store.dispatch("deleteUser", data);
    },
  },
  computed: {
    ...mapGetters({
      institutionAdmins: "getInstitutionAdmins",
    }),
  },
  async mounted() {
    await this.$store.dispatch("fetchInstitutionAdmins");
  },
};
</script>

<style scoped>
.filter-blue {
  filter: invert(33%) sepia(100%) saturate(3814%) hue-rotate(192deg)
    brightness(93%) contrast(102%);
}
</style>