import login from "../../graphql/Auth-graphql/login.gql";
import register from "../../graphql/Auth-graphql/register.gql";
import { apolloClient } from "@/vue-apollo";
import Vue from "vue";
import router from "../../router";
import passwordReset from "@/graphql/Auth-graphql/passwordReset.gql";
import resetPasswordEmail from "@/graphql/Auth-graphql/resetPasswordEmail.gql";
import getUserPermissions from "@/graphql/Auth-graphql/getUserPermissions.gql";
import activateAccount from "@/graphql/Auth-graphql/activateAccount.gql";
import customActivateAccount from '@/graphql/Auth-graphql/customActivateAccount.gql';
import passwordChange from "@/graphql/Auth-graphql/changePassword.gql";
import revokeToken from "@/graphql/Auth-graphql/revokeToken.gql";

export const hasPermission = (permissions, permission) => {
  let p = permissions.find((_permission) => _permission.permissionCode === permission)
  return p ? true : false
}

const AuthStore = {
  state: {
    loggedUser: {},
    userPermissions: [],
    loginLoading: false
  },
  mutations: {
    setLoggedUser(state, user) {

      state.loggedUser = user;
    },
    SET_LOGIN_LOADING(state, loading) {
      state.loginLoading = loading;
    },
    REMOVE_LOGGED_USER(state) {
      state.user = {}
    },
    setUserPermissions(state, permissions) {
      state.userPermissions = permissions
    }
  },
  getters: {
    getLoggedUser: (state) => {
      return state.loggedUser;
    },
    getLoginLoading: (state) => {
      return state.loginLoading;
    },
    getUserPermissions(state) {
      return state.userPermissions
    }
  },

  actions: {
    async userRegister(context, data) {
      const response = await apolloClient.mutate({
        mutation: register,
        variables: {
          email: data.email,
          password1: data.password1,
          password2: data.password2,
          username: data.username
        },
      })
      if (response.data.register.success) {
        Vue.swal({
          icon: "success",
          text: "User registered successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        router.push("/auth/login");
      } else {
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: "Registration failed!"
        });
      }
    },

    async userLogin(context, data) {
       await apolloClient.mutate({
        mutation: login,
        variables: {
          email: data.email,
          password: data.password
        },
      }).then((response) => {
        if (response.data.tokenAuth.success) {
          Vue.swal({
            icon: "success",
            text: "Login successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          context.dispatch("fetchUserPermissions");
          localStorage.setItem("apollo-token", response.data.tokenAuth.token);
          localStorage.setItem('refreshToken', response.data.tokenAuth.refreshToken);
          context.commit("setLoggedUser", response.data.tokenAuth.user);
          router.push("/dashboard");
  
        } else {
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: "Invalid Credentails!"
          });
        }
      }).catch((error) => {
        if (error.networkError.statusCode == 403) {
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: "Too many attempts, account blocked! \n Contact your administrator",
            timer: 1500,
            showConfirmButton: false
          });
        }
        
      })
      

    },
    async activateAccount(context, data) {
      apolloClient.mutate({
        mutation: activateAccount,
        variables: { ...data }
      })
        .then((response) => {
          if (response.data.verifyAccount.success == true) {
            Vue.swal({
              icon: "success",
              text: "Account activated successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured",
              showConfirmButton: false,
              timer: 1500
            });
          }
        })
    },
    async customActivateAccount(context, data) {
      apolloClient.mutate({
        mutation: customActivateAccount,
        variables: { ...data }
      })
        .then((response) => {
          if (response.data.activateAccount.success == true) {
            Vue.swal({
              icon: "success",
              text: "Account activated successfully!",
              showConfirmButton: false,
              timer: 1500,
              
            });
          }
          else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured",
              showConfirmButton: false,
              timer: 1500
            });
          }
        })
    },
    async fetchUserPermissions(context) {
      await apolloClient.query({
        query: getUserPermissions
      })
        .then((response) => {
          if (response.data.myPermissions) {
            context.commit("setUserPermissions", response.data.myPermissions);
          }

          else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Errors occured!",
              timer: 1500,
              showConfirmButton: false,
            });
          }

        }).catch((err) => {
          err
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: "Permission denied!",
            timer: 1500,
            showConfirmButton: false,
          });
        })
    },
    async resetPassword(context, data) {
      await apolloClient.mutate({
        mutation: passwordReset,
        variables: data
      })
        .then((response) => {
          if (response.data.passwordReset.success == true) {
            Vue.swal({
              icon: "success",
              text: "Password changed successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          }

          else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured!",
              timer: 1500,
            });
          }

        })
    },

    async resetPasswordEmail(context, data) {
      await apolloClient.mutate({

        mutation: resetPasswordEmail,
        variables: data
      })
        .then((response) => {



          if (response.data.sendPasswordResetEmail.success == true) {
            Vue.swal({
              icon: "success",
              text: "Email sent successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          }

          else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured!",
              timer: 1500,
            });
          }

        })
    },
    async changePassword(context, data) {
      apolloClient.mutate({
        mutation: passwordChange,
        variables: { ...data }
      })
        .then((response) => {
          if (response.data.passwordChange.success == true) {
            Vue.swal({
              icon: "success",
              text: "Password changed successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured!"
            });
          }
        })
    },

    async revokeToken(context, data) {
      apolloClient.mutate({
        mutation: revokeToken,
        variables: {
          refreshToken: data.refreshToken
        }
      })
        .then((response) => {
          response
          localStorage.removeItem("refreshToken");
        })
    }
  },

};
export default AuthStore;
