<template>
  <sequential-entrance fromTop>
    <v-app id="inspire">
      <v-main>
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center">
            <v-col cols="8" sm="6" md="6">
              <v-card
                :loading="loading"
                class="mx-auto my-12 rounded-xl v-card v-sheet theme--light"
                elevation="10"
              >
                <v-window v-model="step">
                  <v-window-item :value="1">
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-card-text class="mt-12">
                          <div class="text-center mb-7">
                            <v-avatar
                              color="white"
                              size="130"
                              class="emblem-style"
                            >
                              <v-img
                                src="@/assets/images/tz.png"
                                width="150"
                                height="150"
                              />
                            </v-avatar>
                          </div>

                          <v-form>
                            <v-text-field
                              label="Email"
                              placeholder="eg. xxx@xxx.com"
                              v-model="email"
                              type="email"
                              prepend-inner-icon="mdi-email"
                            />
                            <v-text-field
                              label="Password"
                              placeholder="eg. ******"
                              v-model="password"
                              prepend-inner-icon="mdi-lock"
                              :value="mypass"
                              :append-icon="
                                value
                                  ? 'mdi-eye-outline'
                                  : 'mdi-eye-off-outline'
                              "
                              @click:append="() => (value = !value)"
                              :type="value ? 'password' : 'text'"
                            />
                          </v-form>
                          <v-btn
                            depressed
                            color="transparent"
                            class="blue--text"
                            style="text-transform: unset"
                            to="/auth/forgot_password"
                            >Forgot Password?</v-btn
                          >
                          <v-btn
                            class="white--text btn-login btn loginBtn"
                            depressed
                            @click="handleLogin"
                            rounded
                            color="primary"
                            dark
                            >SIGN IN</v-btn
                          >
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" md="4" class="primary">
                        <v-card-text class="white--text mt-12">
                          <h1 class="text-center display-1">Hello, Friend!</h1>
                          <h5 class="text-center">
                            Enter your personal details
                          </h5>
                        </v-card-text>
                        <div class="text-center">
                          <v-btn rounded outlined dark @click="step++"
                            >SIGN UP</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-row class="fill-height">
                      <v-col cols="12" md="4" class="primary">
                        <v-card-text class="white--text mt-12">
                          <h1 class="text-center display-1">Welcome Back!</h1>
                          <h5 class="text-center">
                            To Keep connected with eDodoso please login with
                            your info
                          </h5>
                        </v-card-text>
                        <div class="text-center">
                          <v-btn rounded outlined dark @click="step--"
                            >Sign in</v-btn
                          >
                        </div>
                      </v-col>

                      <v-col cols="12" md="8">
                        <v-card-text class="mt-6">
                          <div class="text-center mb-7">
                            <v-avatar
                              color="white"
                              size="130"
                              class="emblem-style"
                            >
                              <v-img
                                src="@/assets/images/tz.png"
                                width="150"
                                height="150"
                              />
                            </v-avatar>
                          </div>
                          <div class="text-center mb-4"></div>
                          <h4 class="text-center mt-4">
                            Ensure your email for registration
                          </h4>
                          <v-form>
                            <v-text-field
                              v-model="username"
                              :rules="nameRules"
                              prepend-inner-icon="mdi-account"
                              label="username"
                              required
                            ></v-text-field>

                            <v-text-field
                              v-model="email"
                              :rules="emailRules"
                              dense
                              label="E-mail"
                              prepend-inner-icon="mdi-email"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="password"
                              :rules="passwordRules"
                              dense
                              label="Password"
                              prepend-inner-icon="mdi-lock"
                              required
                              :value="mypass"
                              :append-icon="
                                value
                                  ? 'mdi-eye-outline'
                                  : 'mdi-eye-off-outline'
                              "
                              @click:append="() => (value = !value)"
                              :type="value ? 'password' : 'text'"
                            />
                            <v-text-field
                              v-model="password2"
                              :rules="passwordRules"
                              dense
                              label="Re-Enter: Password"
                              prepend-inner-icon="mdi-lock"
                              required
                              :value="mypass"
                              :append-icon="
                                value1
                                  ? 'mdi-eye-outline'
                                  : 'mdi-eye-off-outline'
                              "
                              @click:append="() => (value1 = !value1)"
                              :type="value1 ? 'password' : 'text'"
                            ></v-text-field>
                          </v-form>
                        </v-card-text>
                        <div class="text-center mt-n5">
                          <v-btn
                            :disabled="!valid"
                            color="primary"
                            class="mr-4 my-3"
                            @click="handleRegister"
                            rounded
                            style="text-transform: none; color: white"
                          >
                            Register
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </sequential-entrance>
</template>
<style scoped>
.emblem-style {
  border: 2px solid #efefef;
  box-shadow: 0 0 8px rgb(0 0 0 / 50%);
  padding: 5px;
  border-radius: 50%;
}
.loginBtn {
  margin-left: 50px;
}

#inspire {
  background-image: url("../../assets/images/background1.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
export default {
  data: () => ({
    username: "",
    email: null,
    password: null,
    password2: "",
    value: String,
    value1: String,
    mypass: "",
    step: 1,
    isLoading: false,
    fullPage: true,
    valid: true,
    // loading: false,
    nameRules: [(v) => !!v || "This field is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "This field is required",
      (v) => (v && v.length >= 8) || "Name must not be less than 8 characters",
    ],
  }),
  props: {
    source: String,
  },
  methods: {
    handleLogin() {
      this.loading = true;
      if (this.email) {
        if (this.password) {
          let data = {
            email: this.email,
            password: this.password,
          };
          this.$store.dispatch("userLogin", data);
          // console.log("The data to be sent", data);
        } else {
          Swal.fire({
            toast: true,
            position: "center",
            icon: "error",
            title: "please provide a password",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          icon: "error",
          title: "please provide email",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    handleRegister() {
      if (this.username) {
        if (this.email) {
          if (this.password) {
            if (this.password == this.password2) {
              this.loading = true;
              let data = {
                username: this.username,
                email: this.email,
                password1: this.password,
                password2: this.password2,
              };
              this.$store.dispatch("userRegister", data);
            } else {
              Swal.fire({
                toast: true,
                position: "center",
                icon: "error",
                title: "passwords must match",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } else {
            Swal.fire({
              toast: true,
              position: "center",
              icon: "error",
              title: "please provide a password",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            toast: true,
            position: "center",
            icon: "error",
            title: "please provide an email",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          icon: "error",
          title: "please provide a username",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  mounted() {
    this.loading = false;
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters.getLoginLoading;
      },
      set(value) {
        this.$store.commit("SET_LOGIN_LOADING", value);
      },
    },
  },
};
</script>