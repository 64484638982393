<template>
  <sequential-entrance fromTop>
    <section id="hero">
      <v-row>
        <v-col cols="3" class="pl-8">
          <div class="container-custom pa-8 background">
            <h3 class="font-weight-bold display-0">Survey Categories</h3>

            <v-radio-group class="p-0" dense>
              <v-radio
                class="p-0 text-sm"
                v-for="surveyCategory in surveyCategories"
                :key="surveyCategory.categoryUniqueId"
                :value="surveyCategory.categoryUniqueId"
                @click="
                  filterPublicSurveysByCategory(surveyCategory.categoryUniqueId)
                "
                :label="surveyCategory.categoryName"
              />
            </v-radio-group>
          </div>
        </v-col>
        <v-col cols="9">
          <div class="container-custom pa-8">
            <v-row>
              <v-col cols="10" sm="10" md="10">
                <v-text-field
                  full-width
                  clearable
                  outlined
                  label="Tafuta Survey"
                  placeholder="Tafuta Survey"
                  prepend-inner-icon="mdi-magnify"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <div class="text-center">
                  <v-btn
                    x-large
                    block
                    color="#e6bc22"
                    dark
                    style="border-radius: 10px"
                  >
                    Tafuta
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-btn translate="3" color="white" link to="/" class="mb-2" large>
              <!-- <v-icon size="30" color="primary">
                mdi-home
              </v-icon> -->
              <span class="primary--text"> GO HOME </span>
            </v-btn>
            <v-card
              max-width="100%"
              tile
              elevation="4"
              style="border-radius: 20px"
              class="rounded-xl v-card v-sheet theme--light"
            >
              <v-spacer></v-spacer>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 orange--text mb-1">
                    Public Survey
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <!-- {{service }} -->
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <ListItem
                v-for="publicSurvey in publicSurveys"
                :key="publicSurvey.surveyUniqueId"
                :title="publicSurvey.surveyTitle"
                icon="mdi-file-document-outline"
                showSubtitle="true"
                subtitle="Survey"
                link="true"
                :to="`/response/${publicSurvey.surveyUniqueId}`"
              />
            </v-card>
          </div>
        </v-col>
      </v-row>
    </section>
  </sequential-entrance>
</template>



<script>
import { mapGetters } from "vuex";

import ListItem from "../components/public-components/ListItem";

export default {
  data() {
    return {
      page: 1,
      selectedServices: [],
      dialog: false,
      videoId: "i8IvvHJssWE",
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  components: {
    ListItem,
  },
  computed: {
    ...mapGetters({
      publicSurveys: "getPublicSurveys",

      isAuthenticated: "isLoggedIn",

      surveyCategories: "getAllSurveyCategories",

      privateSurveyId: "getPrivateSurveyId",
    }),
  },

  methods: {
    filterPublicSurveysByCategory(categoryId) {
      this.$store.dispatch("loadSurveyByCategory", categoryId);
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchPublicSurveys");
    await this.$store.dispatch("fetchAllSurveyCategories");
  },
};
</script>

<style lang="scss">
.container-custom {
  background: #fff;
  border-radius: 15px;
  margin-top: 15px;
}
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

.background {
  background-image: url("../assets/mtnew.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 900px;
}
section {
  position: relative;
}
</style>












