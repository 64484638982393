<template>
    <v-card class="mx-auto my-20" width="50%">
      <v-img
        class="white--text align-end"
        height="200px"
        src="../../assets/images/Kilimanjaro.jpg"
      >
      </v-img>
      <div class="text-center"></div>
      <v-card-title> Go to the Login page to login </v-card-title>
      <v-card-actions>
        <v-btn
          color="blue"
          depressed
          style="text-transform: unset"
          class="white--text"
          to="/auth/login"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  <script>
  export default {
    mounted() {
      const token = this.$route.params.token;
      const userId = this.$route.params.userId;
      this.$store.dispatch("customActivateAccount", { token, userId });
    },
  };
  </script>
  