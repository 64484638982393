import Vue from "vue";
import VueRouter from "vue-router";
import SurveyCreator from "@/views/surveys/SurveyCreator.vue";
import SurveyAnalytics from "@/views/surveys/SurveyAnalytics.vue";
import SurveyExporter from "@/views/surveys/SurveyExporter.vue";
import Surveys from "@/views/surveys/Surveys.vue";
import ImportSurveyView from "@/views/surveys/ImportSurveyView.vue";
import SurveyResponseView from "@/views/surveys/SurveyResponseView.vue";
import LandingPageView from "../views/LandingPageView.vue";
import Dashboard from "@/views/Dashboard.vue";
import ManageUsers from "@/views/systemAdmin/ManageUsers.vue";
import PackagesManagement from "@/views/systemAdmin/PackagesManagement.vue";
import ManageInstitutionsView from "@/views/systemAdmin/ManageInstitutionsView.vue";
import authRouter from "@/views/Auth/authRouter.js";
import systemSettingsRouter from "@/views/systemSettings/systemSettingsRouter.js"
// import subscriptionRouter from "@/views/subscriptions/subscriptionRouter.js";
import permissionsView from "@/views/systemSettings/permissionsView.vue";
import PublicSurveysView from "@/views/PublicSurveysView"
import quizesRouter from "../views/quizes/quizesRouter";
// import myPendingSurveys from "@/views/surveys/myPendingSurveys.vue";
import printAnalysis from '@/views/surveys/printAnalysis.vue';
import SurveyCategoryManagement from  "@/views/surveys/SurveyCategoryManagement.vue";

import AnalyticsDatatable from "@/views/surveys/AnalyticsDatatable.vue";


Vue.use(VueRouter);

function checkAuth(to, from, next) {
  let isAuthenticated = localStorage.getItem("apollo-token");

  if (isAuthenticated){
    next();
  } 
  else{
    next("/auth/login");
  }   
}

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPageView,
  },
  {
    path: "/public-surveys",
    name: "PublicSurveys",
    component: PublicSurveysView
  },

  ///// End of the Auth routes /////

  {
    path: "/creator/:surveyId",
    name: "SurveyCreator",
    component: SurveyCreator,
    beforeEnter : checkAuth,
  },
  {
    path: "/surveys",
    name: "Surveys",
    component: Surveys,
    beforeEnter : checkAuth,
  },
  {
    path: "/response/:surveyId",
    name: "SurveyResponseView",
    component: SurveyResponseView,
  },
  // {
  //   path: "/myPendingSurveys",
  //   name: "myPendingSurveys",
  //   component: myPendingSurveys,
  // },
  {
    path: "/survey_category_management",
    name: "SurveyCategoryManagement",
    component: SurveyCategoryManagement,
  },

  {
    path: "/analytics/:surveyId",
    name: "SurveyAnalytics",
    component: SurveyAnalytics,
    beforeEnter : checkAuth,
  },
  {
    path: '/printAnalysis/:surveyId',
    name: 'PrintSurveyAnalysis',
    component: printAnalysis,
    beforeEnter: checkAuth
  },
  {

    path: "/import_survey",
    name: "ImportSurveyView",
    component: ImportSurveyView,
    beforeEnter : checkAuth,

  },
  {

    path: "/analytics_datatable/:surveyId",
    name: "AnalyticsDatatable",
    component: AnalyticsDatatable

  },

  {

    ...authRouter,

  },

  {

    ...systemSettingsRouter,
    beforeEnter : checkAuth,

  },

  // {
  //   ...quizesRouter,
  //   beforeEnter : checkAuth,
  // },
  {
    ...quizesRouter,
  },
  
  {

    path: "/export/:surveyId",
    name: "SurveyExporter",
    component: SurveyExporter,

  },

  {

    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter : checkAuth,

  },

  {

    path: "/manage_users",
    name: "ManageUsers",
    component: ManageUsers,
    beforeEnter : checkAuth,


  },
  {
    path: "/packages_management",
    name: "PackagesManagement",
    component: PackagesManagement,
    beforeEnter : checkAuth,

  },
  {
    path: "/manage_institutions",
    name: "ManageInstitutions",
    component: ManageInstitutionsView,
    beforeEnter : checkAuth,

  },
  {
    path:"/system_settings/permissions/:roleId",
    name: "permissionsView",
    component:permissionsView,
    beforeEnter: checkAuth
  },

  {
    path: "/:catchAll(.)",
    name: "NOT_FOUND",
    component: LandingPageView,
    
    },

];




const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
