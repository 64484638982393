<template>
  <div>
    <survey :survey="survey" />
  </div>
</template>

<script>
import { StylesManager, Model } from "survey-vue";

import "survey-vue/defaultV2.css";

StylesManager.applyTheme("defaultV2");

export default {
  name: "surveyjs-component",
  data() {
    const surveyContent = localStorage.getItem("surveyContent");


    const survey = new Model(surveyContent, {
      showNavigationButtons: true,
    });



    survey.showTitle = false;


    survey.navigationBar;

    survey.showCompletedPage = true;

    survey.getInCorrectAnswerCount = true;


    survey.addNavigationItem({
      title: "Go back",
      action: () => this.$router.go(-2),
    });

    const answers = this.$store.getters.getQuizAnswers;
    survey.data = answers;
    survey.mode = "display";
    survey.questionsOnPageMode = "singlePage";
    survey.showNavigationButtons = "none";
    survey.showProgressBar = "off";
    survey.showTimerPanel = "none";
    survey.maxTimeToFinishPage = 0;
    survey.maxTimeToFinish = 0;
    survey.showNavigationButtons = true;
    const correctStr = "Correct";
    const inCorrectStr = "Incorrect";

    function getTextHtml(text, str, isCorrect) {
      if (text.indexOf(str) < 0) return undefined;
      return (
        text.substring(0, text.indexOf(str)) +
        "<span style='color:" +
        (isCorrect ? "green" : "red") +
        "'>" +
        str +
        "</span>"
      );
    }

    function isAnswerCorrect(q) {
      const right = q.correctAnswer;
      if (!right || q.isEmpty()) return undefined;
      var left = q.value;
      if (!Array.isArray(right)) return right == left;
      if (!Array.isArray(left)) left = [left];
      for (var i = 0; i < left.length; i++) {
        if (right.indexOf(left[i]) < 0) return false;
      }
      return true;
    }
    function renderCorrectAnswer(q) {
      if (!q) return;
      const isCorrect = isAnswerCorrect(q);
      if (!q.prevTitle) {
        q.prevTitle = q.title;
      }
      if (isCorrect === undefined) {
        q.title = q.prevTitle;
      }
      q.title = q.prevTitle + " " + (isCorrect ? correctStr : inCorrectStr);
    }
    survey.onValueChanged.add((sender, options) => {
      renderCorrectAnswer(options.question);
    });

    survey.onTextMarkdown.add((sender, options) => {
      var text = options.text;
      var html = getTextHtml(text, correctStr, true);
      if (!html) {
        html = getTextHtml(text, inCorrectStr, false);
      }
      if (html) {
        options.html = html;
      }
    });
    survey.getAllQuestions().forEach((q) => renderCorrectAnswer(q));

    return {
      survey: survey,
      // survey1: survey1,
    };
  },
};
</script>

<style scoped>
</style>
