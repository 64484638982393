import { apolloClient } from "@/vue-apollo";
import Vue from "vue";
import getAllInstitutions from "@/graphql/manageinstitutions/allInstitutions.gql";
import allUsers from "@/graphql/manageUsers/allUsers.gql";
import registerAdminUser from "@/graphql/manageUsers/registerUserAdmin.gql";
import createInstitutionUser from "@/graphql/manageUsers/registerInstitutionUser.gql";
import assignUserToInstitution from "@/graphql/manageUsers/assignUserToInstitution.gql";
import allInstitutionalAdmins from "@/graphql/manageUsers/allInstitutionAdmin.gql";
import adminsNumber from "@/graphql/manageUsers/adminsNumber.gql";
// import deleteUser from "@/graphql/manageUsers/deleteUser.gql";
import userManagementAnalysis from "@/graphql/manageUsers/userManagementAnalysis.gql";
import editUserDetails from "@/graphql/manageUsers/editUserDetails.gql";
import changeUserStatus from "@/graphql/manageUsers/changeUserStatus.gql";
import uploadUsersExcel from "@/graphql/manageUsers/uploadUsersExcel.gql";

import institutionUsers from "@/graphql/manageUsers/getInstitutionUsers.gql";
import getAccountDetails from "@/graphql/manageUsers/accountDetails.gql";



const manageUsersStore = {
	state: {
		institutions: [],
		allUsers: [],
		currentRegistredAdminId: "",
		institutionAdmins: [],
		adminsNumber: "",
		institutionUsersNumber: "",
		userManagementAnalysis: "",
		activeUsers: [],
		inactiveUsers: [],
		instUsers: [],
		accountDetails: [],
		step:""
	},

	mutations: {
		setCreatedUser(state, user) {
			state.createdUser = user;
		},
		setInstitutions(state, institutions) {
			state.institutions = institutions;
		},
		setAllUsers(state, users) {
			state.allUsers = users;
		},

		setCurrentRegistredAdminId(state, adminId) {
			state.currentRegistredAdminId = adminId;
		},
		setInstitutionAdmins(state, admins) {
			state.institutionAdmins = admins
		},
		setAdminsNumber(state, adminsNumber) {
			state.adminsNumber = adminsNumber
		},
		setInstitutionUsersNumber(state, usersNumber) {
			state.InstitutionUsersNumber = usersNumber
		},
		setUserManagementAnalysis(state, userManagementAnalysis) {
			state.userManagementAnalysis = userManagementAnalysis
		},

		clearActiveUsers(state){
			state.activeUsers = []
		},
		setActiveUsers(state, users) {
			state.activeUsers.push(users);
		},

		clearInactiveUsers(state){
			state.inactiveUsers = []
		},

		setInactiveUsers(state, data){
			state.inactiveUsers.push(data);
		},

		setInstUsers(state, data){
			state.instUsers = data;
		},
		
		activateUser(state, data){
			state.inactiveUsers.splice(
				state.inactiveUsers.findIndex(({pk})=> pk === data.pk),1
			)
		},

		deactiveUsers(state, data){
			state.activeUsers.splice(
				state.activeUsers.findIndex(({pk})=> pk === data.pk),1
			)
		},
		setStep(state, data){
			state.step = data;
		},
		setAccountDetails(state, data){
			state.accountDetails = data;
		}
	},
	actions: {
		async createAdminUser(context, user) {
			await apolloClient.mutate({
				mutation: registerAdminUser,
				variables: user
			})
				.then((response) => {
					if (response.data.newUser.pk) {
						// Vue.swal({
						// 	toast: true,
						// 	icon: "success",
						// 	text: "User added successfully!",
						// 	showConfirmButton: false,
						// 	timer: 1500,
						// });
						context.commit("setCurrentRegistredAdminId", response.data.newUser.pk)
						context.commit("setStep",2);
					}
					else {
						// Vue.swal({
						// 	icon: "error",
						// 	title: "Oops...",
						// 	text: "Error occured!",
						// });
					}
				})
		},
		async createInstUser(context, user) {
			await apolloClient.mutate({
				mutation: createInstitutionUser,
				variables: { ...user }
			})
				.then((response) => {
					if (response.data.newUser.status == 200) {
						context.dispatch('fetchUserManagementAnalysis');
						let tempData = {
							pk: response.data.newUser.pk,
							...user
						}

						context.commit('setActiveUsers',tempData);

						Vue.swal({
							icon: "success",
							text: "User added successfully!",
							showConfirmButton: false,
							timer: 1500,
						});
						context.dispach("fetctActiveUsers", "ACTIVE")
						context.dispatch("fetctInactiveUsers", "INACTIVE");

					}
					else {
						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Error occured!",
						});
					}
				})
		},

		async createUsersByUploadingExcel(context, data){
			await apolloClient.mutate({
				mutation: uploadUsersExcel,
				variables: {
					usersExcel: data
				}
			})
			.then((response)=>{
				if(response.data.uploadUserData.status == 200){
					context.dispatch('fetchUserManagementAnalysis');
					Vue.swal({
						toast: true,
						icon: "success",
						text: "users added successfully!",
						showConfirmButton: false,
						timer: 1500,
					});
				}
				else{
					Vue.swal({
						icon: "error",
						title: "Oops...",
						text: "Error occured! while adding users",
					});
				}
			
			})
		},

		async fetchInstitutions(context) {
			await apolloClient.query({
				fetchPolicy: "no-cache",

				query: getAllInstitutions,
			})
				.then((response) => {
					context.commit("setInstitutions", response.data.getAllInstitutions);
				})
		},

		async fetchInstitutionAdmins(context) {
			await apolloClient.query({
				fetchPolicy: "no-cache",

				query: allInstitutionalAdmins
			})
				.then((response) => {
					context.commit("setInstitutionAdmins", response.data.allInstitutionalAdmins)
				})
		},
		async fetchAdminsNumber(context) {
			await apolloClient.query({
				fetchPolicy: "no-cache",

				query: adminsNumber
			})
				.then((response) => {
					context.commit("setAdminsNumber", response.data.getUserManagementAnalysis.totalAdmins)
				})
		},

		async fetchUserManagementAnalysis(context) {
			await apolloClient.query({
				fetchPolicy: "no-cache",
				query: userManagementAnalysis
			})
				.then((response) => {
					context.commit("setUserManagementAnalysis", response.data.getUserManagementAnalysis)
				})
		},

		async fetctActiveUsers(context, data) {
			context.commit('clearActiveUsers');
			apolloClient.query({
				fetchPolicy: "no-cache",
				query: allUsers,
				variables: { ...data}
			})
				.then((response) => {
					response.data.allUsers.forEach(activeUser => {
						context.commit("setActiveUsers",activeUser);
					})
				})
		},
		async fetctInactiveUsers(context, data) {
			context.commit('clearInactiveUsers');
			apolloClient.query({
				query: allUsers,
				fetchPolicy: 'no-cache',
				variables: {...data}
			})
				.then((response) => {


					context.commit("setInactiveUsers", response.data.allUsers)

					response.data.allUsers.forEach(inactiveUser => {
						context.commit("setInactiveUsers", inactiveUser);
					})

				})
		},

		async assignUserToInstitution(context, data) {
			await apolloClient.mutate({
				mutation: assignUserToInstitution,
				variables: {
					userandinstitution: {
						institutionUid: data.institution,
						userId: data.user
					}
				}
			})
				.then((response) => {
					if (response.data.assignUserToInstitution.status == 200) {
						Vue.swal({
							toast: true,
							icon: "success",
							text: "Administrator added successfully!",
							showConfirmButton: false,
							timer: 1500,
						});
					}
					else {
						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Error occured! while adding admin",
						});
					}
				})
		},

		async editUserDetails(context, data) {
			apolloClient.mutate({
				mutation: editUserDetails,
				variables: { ...data }
			})
				.then((response) => {
					if (response.data.editUser.status == 200) {
						Vue.swal({
							icon: "success",
							text: "User edited successfully!",
							showConfirmButton: false,
							timer: 1500,
						});
						context.commit("setLoggedUser", response.data.tokenAuth.user);
					}
					else {
						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Error occured! while deleting user",
						});
					}
				})
		},
		async activateUser(context, data) {
			apolloClient.mutate({
				mutation: changeUserStatus,
				variables: { ...data }
			})
				.then((response) => {
					if (response.data.changeUserStatus.status == 200) {
						context.dispatch('fetchUserManagementAnalysis');
						context.commit("activateUser", {pk:data.pk});
						Vue.swal({
							icon: "success",
							text: "User activated successfully!",
							showConfirmButton: false,
							timer: 1500,
						});
					}
					else {
						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Error occured! while activating user",
						});
					}
				})
		},

		async deleteUser(context, data) {
			apolloClient.mutate({
				mutation: changeUserStatus,
				variables: { ...data }
			})
				.then((response) => {
					if (response.data.changeUserStatus.status == 200) {
						context.dispatch('fetchUserManagementAnalysis');
						context.commit("deactiveUsers",{pk: data.pk})
						Vue.swal({
							icon: "success",
							text: "User deleted successfully!",
							showConfirmButton: false,
							timer: 1500
						});
					}
					else {
						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Error occured! while deleting user",
						});
					}
				})
		},
		async fetchInstUsers(context, data) {
			await apolloClient.query({
				query: institutionUsers,
				fetchPolicy: 'no-cache',
				variables: { ...data }
			})
				.then((response) => {
					context.commit("setInstUsers", response.data.getInstitutionUsers)
				})

		},
		async fetchAccountDetails(context) {
			await apolloClient.query({
				fetchPolicy: 'no-cache',
				query: getAccountDetails
			})
			.then((response)=>{
				context.commit('setAccountDetails',response.data.getAccountDetails)
				// console.log("The response is", response.data.getAccountDetails)
			})
		}

	},
	getters: {
		getInstitutions: (state) => (state.institutions),
		getActiveUsers: (state) => (state.activeUsers),
		getCurrentRegistredAdminId(state) {
			return state.currentRegistredAdminId

		},
		getInstitutionAdmins(state) {
			return state.institutionAdmins
		},
		getAdminsNumber(state) {
			return state.adminsNumber
		},
		getInstitutionUsersNumber(state) {
			// console.log(state.institutionUser)
			return state.institutionUsersNumber
		},

		getUserManagementAnalysis(state) {
			return state.userManagementAnalysis
		},
		getInactiveUsers(state) {
			return state.inactiveUsers
		},
		getInstUsers(state){
			return state.instUsers
		},
		getStep(state){
			return state.step;
		},
		getAccountDetails(state){
			return state.accountDetails
		}
	}

};

export default manageUsersStore;
