<template>
  <AnalyticsDatatable />
</template>

<script>
import AnalyticsDatatable from "@/components/surveys/AnalyticsDatatables.vue"
export default {
    components:{
        AnalyticsDatatable
    }
}
</script>
