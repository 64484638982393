<template>
  <v-app class="background">
    <Topnav />
    <SurveysSidenav />
    <v-container fluid>
      <v-row class="mx-">
        <v-col sm="12" md="6" lg="3" v-for="item in cards" :key="item.title">
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 5 : 2"
              :class="{ up: hover }"
              outlined
              link
              color="rgba(255,255,255,0)"
              :to="item.link"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">{{ item.title }}</div>
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'ACTIVE'"
                    >{{ surveyAnalysis.activeSurveys }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'DRAFT'"
                    >{{ surveyAnalysis.draftSurveys }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'INACTIVE'"
                    >{{ surveyAnalysis.inactiveSurveys }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'TO RESPOND'"
                    >
                    {{surveyRespondentsAnalysis.totalQuiz}}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar tile size="80" color="white">
                  <v-icon
                    style="font-size: 72px"
                    dark
                    large
                    :color="item.color"
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-divider class="my-3" />
    </v-container>
    <v-container fluid class="mb-3">
      <v-row>
        <v-col>
          <v-dialog v-model="dialog" persistent width="40%">
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-btn
                color="white"
                elevation="2"
                class="primary--text"
                v-bind="attrs"
                v-on="{ ...dialog }"
              >
                CREATE QUIZ
              </v-btn>
            </template>
            <v-card color="rgba(255,255,255,1)">
              <v-card-title class="text-h7 primary--text">
                Create quiz
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="surveyTitle"
                  dense
                  label="Quiz Title"
                  outlined
                  required
                />

                <v-select
                  v-model="categoryUniqueId"
                  label="Quiz category"
                  outlined
                  :items="categories"
                  item-value="categoryUniqueId"
                  item-text="categoryName"
                >
                </v-select>

                <v-row>
                  <v-col cols="12" lg="6">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="Start date"
                          hint="MM/DD/YYYY"
                          persistent-hint
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="startDate"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        @input="menu1 = false"
                        :min="new Date(Date.now()).toISOString().substr(0, 10)"
                      />
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="End date"
                          hint="MM/DD/YYYY"
                          persistent-hint
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="endDate"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        @input="menu2 = false"
                        :min="startDate"
                      />
                    </v-menu>
                  </v-col>
                </v-row>

                <v-radio-group v-model="access" row>
                  <v-radio label="Public" value="public"></v-radio>
                  <v-radio label="Private" value="private"></v-radio>
                </v-radio-group>

                <v-dialog
                  v-model="dialog1"
                  persistent
                  width="50%"
                  v-if="access == 'private'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      style="text-transform: unset; color: white"
                    >
                      Specify Respondents
                    </v-btn>
                  </template>
                  <v-card style="width: 98%" class="mx-2">
                    <v-toolbar color="primary" dark>
                      <v-icon size="40">mdi-home-search-outline</v-icon>
                      <v-toolbar-title>Institutions</v-toolbar-title>
                    </v-toolbar>
                    <v-row>
                      <v-col>
                        <v-expansion-panels focusable>
                          <v-expansion-panel
                            v-for="institution in allInstitutions"
                            :key="institution.uniqueId"
                            @click="goToinstitutionUsers(institution.uniqueId)"
                          >
                            <v-expansion-panel-header>
                              {{ institution.institutionName }}
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <v-container fluid>
                                <v-row>
                                  <v-col
                                    cols="4"
                                    sm="4"
                                    md="3"
                                    v-for="user in institutionUsers"
                                    :key="user.pk"
                                  >
                                    <v-checkbox
                                      dense
                                      :label="user.username"
                                      :value="user.email"
                                      :input-value="selected"
                                      @change="
                                        getSelectedUsers($event, user.email)
                                      "
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="white--text"
                        color="red"
                        large
                        style="text-transform: unset"
                        @click="dialog1 = false"
                      >
                        cancel
                      </v-btn>
                      <v-btn
                        class="white--text"
                        color="blue"
                        large
                        style="text-transform: unset"
                        @click="boundUserEmails()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- End of the Respondents card -->
                </v-dialog>

                <v-layout justify-end>
                  <v-btn
                    color="red"
                    @click="dialog = false"
                    style="text-transform: unset"
                    class="mx-2 white--text"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    @click="createNewSurvey"
                    color="primary"
                    style="text-transform: unset"
                  >
                    Save
                  </v-btn>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab" background-color="#f2f2f2">
          <v-tab @click="getDraftSurveys()">
            <h1 style="color: #1a568a">Draft quizes</h1>
          </v-tab>
          <v-tab @click="getActiveSurveys()">
            <h1 style="color: #11662e">Active quizes</h1>
          </v-tab>
          <v-tab @click="getInactiveSurveys()">
            <h1 style="color: #db9b32">Inactive quizes</h1>
          </v-tab>
          <v-tab @click="getQuizesToRespond()">
            <h1 style="color: #db9b32">QUIZES TO RESPOND</h1>
          </v-tab>
        </v-tabs>

        <v-divider></v-divider>
        <v-divider></v-divider>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card elevation="2">
              <v-card-title class="blue--text">
                <h2>Draft quizes</h2>

                <v-spacer></v-spacer>

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-card-title>

              <v-data-table
                :headers="headers"
                :items="draftSurvey"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu app offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        slot="activator"
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item link :to="'/creator/' + item.surveyUniqueId">
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                          >
                            mdi-file-edit-outline
                          </v-icon>

                          <span
                            style="text-transform: unset"
                            class="mx-5 primary--text"
                            >Edit quiz
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="activateSurvey(item.surveyUniqueId)"
                      >
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="success"
                          >
                            mdi-file-edit-outline
                          </v-icon>

                          <span
                            style="text-transform: unset"
                            class="mx-5 success--text"
                            >Activate quiz
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item link :to="'/export/' + item.surveyUniqueId">
                        <v-list-item-title>
                          <v-icon
                            style="height: 20px; font-size: 16px"
                            color="primary"
                          >
                            mdi-file-pdf-box
                          </v-icon>

                          <span
                            class="mx-5 primary--text"
                            style="text-transform: unset"
                            >Export as pdf
                          </span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        link
                        @click="deleteSurvey(item.surveyUniqueId)"
                      >
                        <v-list-item-title class="red--text"
                          ><v-icon
                            style="height: 16px; font-size: 16px"
                            color="red"
                            >mdi-trash-can-outline</v-icon
                          >
                          <span
                            style="text-transform: unset"
                            class="red--text mx-5"
                            >Delete quiz
                          </span></v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <!---------------------------------- The EN D OF THE SECOND TAB ----------------------------->
          <v-tab-item>
            <v-card elevation="10">
              <v-card-title class="blue--text">
                <h2>Active quiz</h2>

                <v-spacer></v-spacer>

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-card-title>

              <v-data-table
                :headers="headers"
                :items="activeSurveys"
                :search="search"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu app offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        slot="activator"
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item link :to="'/creator/' + item.surveyUniqueId">
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                          >
                            mdi-file-edit-outline
                          </v-icon>

                          <span
                            style="text-transform: unset"
                            class="primary--text mx-5"
                            >Edit quiz</span
                          >
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        link
                        :to="'/analytics/' + item.surveyUniqueId"
                      >
                        <v-list-item-title
                          ><v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                            >mdi-chart-line</v-icon
                          >
                          <span
                            depressed
                            style="text-transform: unset"
                            class="primary--text mx-5"
                            >Quiz Analytics</span
                          ></v-list-item-title
                        ></v-list-item
                      >
                      <v-list-item link>
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                          >
                            mdi-sync
                          </v-icon>

                          <v-dialog
                            v-model="dialog2"
                            persistent
                            max-width="400px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="primary--text mx-5"
                                style="text-transform: unset"
                                @click="takeId(item.surveyUniqueId)"
                              >
                                Re-use quiz
                              </span>
                            </template>

                            <v-card color="rgba(255,255,255,1)">
                              <v-card-title class="text-h7 primary--text mb-3">
                                Re-use quiz
                              </v-card-title>

                              <v-card-text>
                                <v-row>
                                  <!-- The start date --->

                                  <v-col cols="12" lg="6">
                                    <v-menu
                                      ref="menu1"
                                      v-model="menu1"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="startDate"
                                          label="Start date"
                                          hint="MM/DD/YYYY format"
                                          persistent-hint
                                          outlined
                                          dense
                                          prepend-inner-icon="mdi-calendar"
                                          v-bind="attrs"
                                          @blur="startDate"
                                          v-on="on"
                                        />
                                      </template>

                                      <v-date-picker
                                        v-model="startDate"
                                        no-title
                                        @input="menu1 = false"
                                      />
                                    </v-menu>
                                  </v-col>

                                  <!----- THE END DATE ------->
                                  <v-col>
                                    <v-menu
                                      ref="menu2"
                                      v-model="menu2"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="endDate"
                                          label="End date"
                                          hint="MM/DD/YYYY format"
                                          persistent-hint
                                          outlined
                                          dense
                                          prepend-inner-icon="mdi-calendar"
                                          v-bind="attrs"
                                          @blur="endDate"
                                          v-on="on"
                                        />
                                      </template>

                                      <v-date-picker
                                        v-model="endDate"
                                        no-title
                                        @input="menu2 = false"
                                      />
                                    </v-menu>
                                  </v-col>
                                </v-row>

                                <v-radio-group v-model="access" row>
                                  <v-radio label="Public" value="public" />

                                  <v-radio label="Private" value="private" />
                                </v-radio-group>

                                <v-layout justify-end class="mt-3">
                                  <v-btn
                                    color="red"
                                    class="mx-2 white--text"
                                    style="text-transform: unset"
                                    @click="dialog2 = false"
                                  >
                                    Cancel
                                  </v-btn>

                                  <v-btn
                                    @click="copySurvey"
                                    color="primary"
                                    style="text-transform: unset"
                                  >
                                    Save
                                  </v-btn>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item link :to="'/export/' + item.surveyUniqueId">
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                          >
                            mdi-file-pdf-box
                          </v-icon>

                          <span
                            style="text-transform: unset"
                            class="primary--text mx-5"
                            >Export as pdf
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="deactivateSurvey(item.surveyUniqueId)"
                      >
                        <v-list-item-title class="red--text"
                          ><v-icon
                            style="height: 16px; font-size: 16px"
                            class="warning--text"
                            >mdi-trash-can-outline</v-icon
                          >
                          <span
                            style="text-transform: unset"
                            class="warning--text mx-5"
                            >Deactivate quiz
                          </span></v-list-item-title
                        >
                      </v-list-item>

                      <v-list-item
                        link
                        @click="deleteSurvey(item.surveyUniqueId)"
                      >
                        <v-list-item-title class="red--text"
                          ><v-icon
                            style="height: 16px; font-size: 16px"
                            color="red"
                            >mdi-trash-can-outline</v-icon
                          >
                          <span
                            style="text-transform: unset"
                            class="red--text mx-5"
                            >Delete quiz
                          </span></v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <!------------------   END OF THE THIRD  TAB ------------>
          <v-tab-item>
            <v-card elevation="10">
              <v-card-title class="blue--text">
                <h2>Inactive quizes</h2>

                <v-spacer></v-spacer>

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-card-title>

              <v-data-table
                :headers="headers"
                :items="inActiveSurveys"
                :search="search"
              >
                <!----------------->
                <template v-slot:item.actions="{ item }">
                  <v-menu app offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        slot="activator"
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                      <v-list-item link :to="'/creator/' + item.surveyUniqueId">
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                          >
                            mdi-file-edit-outline
                          </v-icon>

                          <span
                            depressed
                            style="text-transform: unset"
                            class="primary--text mx-5"
                            >Edit quiz</span
                          >
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        link
                        :to="'/analytics/' + item.surveyUniqueId"
                      >
                        <v-list-item-title
                          ><v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                            >mdi-chart-line</v-icon
                          >
                          <span
                            depressed
                            style="text-transform: unset"
                            class="primary--text mx-5"
                            >Quiz Analytics
                          </span></v-list-item-title
                        ></v-list-item
                      >
                      <v-list-item link>
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                          >
                            mdi-sync
                          </v-icon>

                          <v-dialog
                            v-model="dialog2"
                            persistent
                            max-width="400px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                style="text-transform: unset"
                                class="primary--text mx-5"
                                @click="takeId(item.surveyUniqueId)"
                              >
                                Re-use quiz
                              </span>
                            </template>

                            <v-card color="rgba(255,255,255,1)">
                              <v-card-title class="text-h7 primary--text mb-3">
                                Re-use quiz
                              </v-card-title>

                              <v-card-text>
                                <v-row>
                                  <v-col cols="12" lg="6">
                                    <v-menu
                                      ref="menu1"
                                      v-model="menu1"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="startDate"
                                          label="Start date"
                                          hint="MM/DD/YYYY format"
                                          persistent-hint
                                          outlined
                                          denseF
                                          prepend-inner-icon="mdi-calendar"
                                          v-bind="attrs"
                                          @blur="startDate"
                                          v-on="on"
                                        />
                                      </template>

                                      <v-date-picker
                                        v-model="startDate"
                                        no-title
                                        @input="menu1 = false"
                                      />
                                    </v-menu>
                                  </v-col>

                                  <!----- THE END DATE ------->
                                  <v-col>
                                    <v-menu
                                      ref="menu2"
                                      v-model="menu2"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="endDate"
                                          label="End date"
                                          hint="MM/DD/YYYY format"
                                          persistent-hint
                                          outlined
                                          dense
                                          prepend-inner-icon="mdi-calendar"
                                          v-bind="attrs"
                                          @blur="endDate"
                                          v-on="on"
                                        />
                                      </template>

                                      <v-date-picker
                                        v-model="endDate"
                                        no-title
                                        @input="menu2 = false"
                                      />
                                    </v-menu>
                                  </v-col>
                                </v-row>

                                <v-radio-group v-model="access" row>
                                  <v-radio label="Public" value="public" />

                                  <v-radio label="Private" value="private" />
                                </v-radio-group>

                                <v-layout justify-end>
                                  <v-btn
                                    color="red "
                                    class="mx-2 white--text"
                                    style="text-transform: unset"
                                    @click="dialog2 = false"
                                  >
                                    Cancel
                                  </v-btn>

                                  <v-btn
                                    @click="copySurvey"
                                    color="primary"
                                    style="text-transform: unset"
                                  >
                                    Save
                                  </v-btn>
                                </v-layout>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item link :to="'/export/' + item.surveyUniqueId">
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="primary"
                          >
                            mdi-file-pdf-box
                          </v-icon>
                          <span
                            style="text-transform: unset"
                            class="mx-5 primary--text"
                            >Export as pdf
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="activateSurvey(item.surveyUniqueId)"
                      >
                        <v-list-item-title>
                          <v-icon
                            style="height: 16px; font-size: 16px"
                            color="success"
                          >
                            mdi-file-edit-outline
                          </v-icon>

                          <span
                            style="text-transform: unset"
                            class="mx-5 success--text"
                            >Activate quiz
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="deleteSurvey(item.surveyUniqueId)"
                      >
                        <v-list-item-title class="red--text"
                          ><v-icon
                            style="height: 16px; font-size: 16px"
                            color="red"
                            >mdi-trash-can-outline</v-icon
                          >
                          <v-btn
                            depressed
                            style="text-transform: unset"
                            color="white"
                            class="red--text"
                            >Delete quiz
                          </v-btn></v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <!--- The quizes to respond  TAB ----->
          <v-tab-item>
            <QuizesToRespond />
          </v-tab-item>
          <!--- The end of the quizes to respond TAB -->
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Topnav from "@/components/navigators/Topnav.vue";

import SurveysSidenav from "@/components/navigators/Sidenav.vue";
import QuizesToRespond from "@/views/quizes/QuizesToRespond.vue";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  components: {
    Topnav,
    SurveysSidenav,
    QuizesToRespond,
  },

  data() {
    return {
      surveyTitle: null,
      dataCreated: false,
      myPass: "",
      dialog: false,
      dialog1: false,
      dialog2: false,
      selected: [],
      emails: [],
      instUserEmails: [],
      tab: null,
      uniqueId: "",
      search: "",
      surveyUniqueId: "",
      startDate: new Date(Date.now()).toISOString().substr(0, 10),
      endDate: new Date(Date.now()).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      access: null,
      categoryUniqueId: "",
      headers: [
        {
          text: "Survey Number",
          align: "start",
          sortable: false,
          value: "surveyNumber",
        },
        { text: "survey title", value: "surveyTitle" },
        { text: "Start date", value: "surveyStartDate" },
        { text: "End date", value: "surveyEndDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      surveyQuiz: "",
      surveyType: "",
      surveyTypes: [
        { name: "QUIZ", value: "quiz" },
        { name: "NORMAL SURVEY", value: "survey" },
      ],
      selectedinstitutionUsers: [],
      cards: [
        {
          title: "ACTIVE",
          icon: "mdi-checkbox-multiple-marked-circle",
          color: "#11662e",
        },
        {
          title: "DRAFT",
          icon: "mdi-checkerboard",
          color: "#db9b32",
        },
        {
          title: "INACTIVE",
          icon: "mdi-book-edit",
          color: "#1a568a",
        },
        {
          title: "TO RESPOND",
          icon: "mdi-book-alert",
          color: "#db9b32",
        },
      ],
    };
  },
  methods: {
    async createNewSurvey() {
      const data = {
        surveyTitle: this.surveyTitle,
        categoryUniqueId: this.categoryUniqueId,
        surveyStartDate: this.startDate,
        surveyEndDate: this.endDate,
        access: this.access,
        respondents: this.instUserEmails.emails,
        surveyType: "quiz",
      };

      if (this.endDate > this.startDate || this.endDate === this.startDate) {
        if (this.surveyTitle) {
          await this.$store.dispatch("newSurvey", data);
          this.dialog = false;
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "quiz title should not be empty",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          icon: "error",
          title: "End date must be equal or greater than the start date",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    boundUserEmails() {
      var data = {
        emails: this.selected.map((email) => ({
          email: email,
        })),
      };
      this.instUserEmails = data;

      this.dialog1 = false;
    },
    getDraftSurveys() {
      this.$store.dispatch("fetchDraftSurveys", {
        surveyStatus: "DRAFT",
        surveyType: "quiz",
      });
    },
    getActiveSurveys() {
      this.$store.dispatch("fetchActiveSurveys", {
        surveyStatus: "ACTIVE",
        surveyType: "quiz",
      });
    },
    getInactiveSurveys() {
      this.$store.dispatch("fetchInactiveSurveys", {
        surveyStatus: "INACTIVE",
        surveyType: "quiz",
      });
    },
    callToCreateSurvey() {
      this.createSurveyDialog = true;
    },

    takeId(surveyId) {
      this.surveyUniqueId = surveyId;
    },
    activateSurvey(surveyUniqueId) {
      const status = "ACTIVE";
      const type = "quiz";
      let data = {
        surveyStatus: status,
        surveyType: type,
        uniqueId: surveyUniqueId,
      };

      this.$store.dispatch("activateSurvey", data);
    },
    deactivateSurvey(surveyUniqueId) {
      const status = "INACTIVE";
      const type = "quiz";
      let data = {
        surveyStatus: status,
        surveyType: type,
        uniqueId: surveyUniqueId,
      };
      this.$store.dispatch("deactivateSurvey", data);
    },
    deleteSurvey(surveyUniqueId) {
      const status = "DELETED";
      const type = "quiz";
      let data = {
        surveyStatus: status,
        uniqueId: surveyUniqueId,
        surveyType: type,
      };
      this.$store.dispatch("removeSurvey", data);
    },
    getQuizesToRespond() {
      this.$store.dispatch("loadPrivateSurveyToRespond",{surveyType:'quiz'});
    },

    copySurvey() {
      const data = {
        surveyUniqueId: this.surveyUniqueId,
        surveyStartdate: this.startDate,
        surveyEnddate: this.endDate,
        surveyAccess: this.access,
      };

      this.$store.dispatch("surveyCopying", data);

      this.dialog = false;
    },
    goToinstitutionUsers(uniqueId) {
      var data = {
        institutionUniqueId: uniqueId,
      };

      this.$store.dispatch("fetchInstUsers", data);
    },

    getSelectedUsers(evt, email) {
      let idx = this.selected.indexOf(email);

      if (idx === -1) {
        this.selected.push(email);
      } else if (idx !== -1) {
        this.selected.splice(idx, 1);
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchSurveyAnalysis", "quiz");
    await this.$store.dispatch("fetchAllSurveyCategories");
    await this.$store.dispatch("fetchAllInstitutions");
    await this.$store.dispatch("fetchSurveyRespondentsAnalysis");
    await this.$store.dispatch("fetchDraftSurveys", {
      surveyStatus: "DRAFT",
      surveyType: "quiz",
    });
    await this.$store.dispatch("fetchActiveSurveys", {
      surveyStatus: "ACTIVE",
      surveyType: "quiz",
    });
    await this.$store.dispatch("fetchInactiveSurveys", {
      surveyStatus: "INACTIVE",
      surveyType: "quiz",
    });
  },
  computed: {
    ...mapGetters({
      surveysList: "getAllSurveys",
      draftSurvey: "getDraftSurveys",
      activeSurveys: "getActiveSurveys",
      inActiveSurveys: "getInActiveSurveys",
      surveyAnalysis: "getSurveyAnalysis",
      categories: "getAllSurveyCategories",
      allInstitutions: "getAllInstitutions",
      institutionUsers: "getInstUsers",
      surveyRespondentsAnalysis : "getSurveyRespondentsAnalysis"
    }),
  },
};
</script>

<style scoped>
.button {
  margin-left: 1300px;
}
.scroll {
  overflow-y: auto;
}
.background {
  background-color: aliceblue;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>