<template>
  <v-app class="background">
    <Topnav />
    <SurveysSidenav />

    <v-container fluid>
      <v-row class="mx-">
        <v-col sm="12" md="6" lg="3" v-for="item in cards" :key="item.title">
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 5 : 2"
              :class="{ up: hover }"
              outlined
              link
              color="rgba(255,255,255,0)"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">{{ item.title }}</div>
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'ACTIVE'"
                    >{{ surveyAnalysis.activeSurveys }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'DRAFT'"
                    >{{ surveyAnalysis.draftSurveys }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'INACTIVE'"
                    >{{ surveyAnalysis.inactiveSurveys }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-h5 mb-1"
                    v-if="item.title == 'TO RESPOND'"
                  >{{surveyRespondentsAnalysis.totalSurveys}}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar tile size="80" color="white">
                  <v-icon
                    style="font-size: 72px"
                    dark
                    large
                    :color="item.color"
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-divider class="my-3" />
    </v-container>

    <v-container fluid>
      <v-row class="d-flex" justify="start">
        <v-col>
          <v-dialog v-model="dialog" persistent width="40%">
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-btn
                color="white"
                elevation="2"
                class="primary--text mx-17"
                v-bind="attrs"
                v-on="{ ...dialog }"
              >
                CREATE SURVEY
              </v-btn>
            </template>
            <v-card color="rgba(255,255,255,1)">
              <v-card-title class="text-h7 primary--text">
                Create Survey
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="surveyTitle"
                  dense
                  label="Survey Title"
                  outlined
                  required
                  clearable
                />

                <v-select
                  v-model="categoryUniqueId"
                  label="Survey category"
                  outlined
                  :items="categories"
                  item-value="categoryUniqueId"
                  item-text="categoryName"
                >
                </v-select>

                <v-row>
                  <v-col cols="12" lg="6">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="Start date"
                          hint="MM/DD/YYYY"
                          persistent-hint
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="startDate"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        @input="menu1 = false"
                        :min="new Date(Date.now()).toISOString().substr(0, 10)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="End date"
                          hint="MM/DD/YYYY"
                          persistent-hint
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          @blur="endDate"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        @input="menu2 = false"
                        :min="startDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-radio-group v-model="access" row>
                  <v-radio label="Public" value="public"></v-radio>
                  <v-radio label="Private" value="private"></v-radio>
                </v-radio-group>
                <v-dialog
                  v-model="dialog1"
                  persistent
                  width="50%"
                  v-if="access == 'private'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      style="text-transform: unset; color: white"
                    >
                      Specify Respondents
                    </v-btn>
                  </template>

                  <!-- Respondents card -->
                  <v-card style="width: 98%" class="mx-2">
                    <v-toolbar color="primary" dark>
                      <v-icon size="40">mdi-home-search-outline</v-icon>
                      <v-toolbar-title>Institutions</v-toolbar-title>
                    </v-toolbar>
                    <v-row>
                      <v-col>
                        <v-expansion-panels focusable>
                          <v-expansion-panel
                            v-for="institution in allInstitutions"
                            :key="institution.uniqueId"
                            @click="goToinstitutionUsers(institution.uniqueId)"
                          >
                            <v-expansion-panel-header>
                              {{ institution.institutionName }}
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <v-container fluid>
                                <v-row>
                                  <v-col
                                    cols="4"
                                    sm="4"
                                    md="3"
                                    v-for="user in institutionUsers"
                                    :key="user.pk"
                                  >
                                    <v-checkbox
                                      dense
                                      :label="user.username"
                                      :value="user.email"
                                      :input-value="selected"
                                      @change="
                                        getSelectedUsers($event, user.email)
                                      "
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="white--text"
                        color="red"
                        large
                        style="text-transform: unset"
                        @click="dialog1 = false"
                      >
                        cancel
                      </v-btn>
                      <v-btn
                        class="white--text"
                        color="blue"
                        large
                        style="text-transform: unset"
                        @click="boundUserEmails()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- End of the Respondents card -->
                </v-dialog>

                <v-layout justify-end>
                  <v-btn
                    color="red"
                    @click="dialog = false"
                    style="text-transform: unset"
                    class="mx-2 white--text"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    @click="createNewSurvey"
                    color="primary"
                    style="text-transform: unset"
                  >
                    Save
                  </v-btn>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab" background-color="#f2f2f2">
          <v-tab @click="getDraftSurveys()">
            <h1 style="color: #1a568a">Draft surveys</h1>
          </v-tab>
          <v-tab @click="getActiveSurveys()">
            <h1 style="color: #11662e">Active surveys</h1>
          </v-tab>
          <v-tab @click="getInactiveSurveys()">
            <h1 style="color: #db9b32">Inactive surveys</h1>
          </v-tab>
          <v-tab @click="getSurveysToRespond()">
            <h1 style="color: #db9b32">Surveys To respond</h1>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <DraftSurveys />
          </v-tab-item>
          <!---------------------------------- The EN D OF THE SECOND TAB ----------------------------->
          <v-tab-item>
            <ActiveSurveys />
          </v-tab-item>
          <!------------------   END OF THE THIRD  TAB ------------>
          <v-tab-item>
            <InactiveSurveys />
          </v-tab-item>
          <!---- The surveys to respond TAB ---->
          <v-tab-item>
            <SurveysToRespond />
          </v-tab-item>
          <!--- The end of the Respond to survey TAB ----->
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-app>
</template>
<style scoped>
.button {
  margin-left: 1300px;
}
</style>

<script>
import Topnav from "@/components/navigators/Topnav.vue";

import SurveysSidenav from "@/components/navigators/Sidenav.vue";
import SurveysToRespond from "@/views/surveys/SurveysToRespond.vue";
import DraftSurveys from "@/components/survey-management/DraftSurveys.vue";
import ActiveSurveys from "@/components/survey-management/ActiveSurveys.vue";
import InactiveSurveys from "@/components/survey-management/InactiveSurveys.vue";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  components: {
    Topnav,
    SurveysSidenav,
    SurveysToRespond,
    DraftSurveys,
    ActiveSurveys,
    InactiveSurveys
  },

  data() {
    return {
      surveyTitle: null,
      dataCreated: false,
      myPass: "",
      dialog: false,
      dialog1: false,
      dialog2: false,
      selected: [],
      emails: [],
      instUserEmails: [],
      tab: null,
      uniqueId: "",
      search: "",
      surveyUniqueId: "",

      startDate: new Date(Date.now()).toISOString().substr(0, 10),
      endDate: new Date(Date.now()).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      access: null,
      categoryUniqueId: "",
      headers: [
        {
          text: "Survey Number",
          align: "start",
          sortable: false,
          value: "surveyNumber",
        },
        { text: "survey title", value: "surveyTitle" },
        { text: "Accessibility", value: "access" },
        { text: "Start date", value: "surveyStartDate" },
        { text: "End date", value: "surveyEndDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      surveyQuiz: "",
      surveyType: "",
      surveyTypes: [
        { name: "QUIZ", value: "quiz" },
        { name: "NORMAL SURVEY", value: "survey" },
      ],
      selectedinstitutionUsers: [],
      cards: [
        {
          title: "ACTIVE",
          icon: "mdi-checkbox-multiple-marked-circle",
          color: "#11662e",
        },
        {
          title: "DRAFT",
          icon: "mdi-checkerboard",
          color: "#db9b32",
        },
        {
          title: "INACTIVE",
          icon: "mdi-book-edit",
          color: "#1a568a",
        },
        {
          title: "TO RESPOND",
          icon: "mdi-book-alert",
          color: "#db9b32",
        },
      ],
    };
  },
  methods: {
    async createNewSurvey() {
      const data = {
        surveyTitle: this.surveyTitle,
        categoryUniqueId: this.categoryUniqueId,
        surveyStartDate: this.startDate,
        surveyEndDate: this.endDate,
        access: this.access,
        respondents: this.instUserEmails.emails,
        surveyType: "survey",
      };

      if (this.endDate > this.startDate || this.endDate === this.startDate) {
        if (this.surveyTitle) {
          await this.$store.dispatch("newSurvey", data);
       
          this.dialog = false;
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "survey title should not be empty",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          toast: true,
          position: "center",
          icon: "error",
          title: "End date must be equal or greater than the start date",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      await this.$store.dispatch("surveysList");
    },
    boundUserEmails() {
      var data = {
        emails: this.selected.map((email) => ({
          email: email,
        })),
      };
      this.instUserEmails = data;

      this.dialog1 = false;
    },
    getDraftSurveys() {
      const status = "DRAFT";

      const data = {
        surveyStatus: status,
        surveyType: "survey",
      };
      this.$store.dispatch("fetchDraftSurveys", data);
    },
    getActiveSurveys() {
      const status = "ACTIVE";
      const data = {
        surveyStatus: status,
        surveyType: "survey",
      };
      this.$store.dispatch("fetchActiveSurveys", data);
    },
    getInactiveSurveys() {
      const status = "INACTIVE";
      const data = {
        surveyStatus: status,
        surveyType: "survey",
      };
      this.$store.dispatch("fetchInactiveSurveys", data);
    },
    callToCreateSurvey() {
      this.createSurveyDialog = true;
    },
    takeId(surveyId) {
      this.surveyUniqueId = surveyId;
    },
    activateSurvey(surveyUniqueId) {
      const status = "ACTIVE";
      const type = "survey";
      let data = {
        surveyStatus: status,
        surveyType: type,
        uniqueId: surveyUniqueId,
      };

      this.$store.dispatch("activateSurvey", data);
    },
    deactivateSurvey(surveyUniqueId) {
      const status = "INACTIVE";
      const type = "survey";
      let data = {
        surveyStatus: status,
        surveyType: type,
        uniqueId: surveyUniqueId,
      };
      this.$store.dispatch("deactivateSurvey", data);
    },

    deleteSurvey(surveyUniqueId) {
      const status = "DELETED";
      const type = "quiz";
      let data = {
        surveyStatus: status,
        uniqueId: surveyUniqueId,
        surveyType: type,
      };
      this.$store.dispatch("removeSurvey", data);
    },

    copySurvey() {
      const data = {
        surveyUniqueId: this.surveyUniqueId,
        surveyStartdate: this.startDate,
        surveyEnddate: this.endDate,
        surveyAccess: this.access,
      };

      this.$store.dispatch("surveyCopying", data);

      this.dialog2 = false;
    },
    getSurveysToRespond(){
      this.$store.dispatch("loadPrivateSurveyToRespond", {surveyType: 'survey'});
    },
    goToinstitutionUsers(uniqueId) {
      var data = {
        institutionUniqueId: uniqueId,
      };

      this.$store.dispatch("fetchInstUsers", data);
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedinstitutionUsers = [];
        } else {
          this.selectedinstitutionUsers = this.institutionUsers.slice();
        }
      });
    },

    getSelectedUsers(evt, email) {
      let idx = this.selected.indexOf(email);

      if (idx === -1) {
        this.selected.push(email);
      } else if (idx !== -1) {
        this.selected.splice(idx, 1);
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchSurveyAnalysis", "survey");
    await this.$store.dispatch("fetchAllSurveyCategories");
    await this.$store.dispatch("fetchAllInstitutions");
    await this.$store.dispatch("fetchSurveyRespondentsAnalysis");
    await this.$store.dispatch("fetchDraftSurveys", {
      surveyStatus: "DRAFT",
      surveyType: "survey",
    });
    await this.$store.dispatch("fetchActiveSurveys", {
      surveyStatus: "ACTIVE",
      surveyType: "survey",
    });
    await this.$store.dispatch("fetchInactiveSurveys", {
      surveyStatus: "INACTIVE",
      surveyType: "survey",
    });
  },
  computed: {
    ...mapGetters({
      surveysList: "getAllSurveys",
      activeSurveys: "getActiveSurveys",
      inActiveSurveys: "getInActiveSurveys",
      surveyAnalysis: "getSurveyAnalysis",
      categories: "getAllSurveyCategories",
      allInstitutions: "getAllInstitutions",
      institutionUsers: "getInstUsers",
      surveyRespondentsAnalysis : "getSurveyRespondentsAnalysis"
    }),

    Surveycards() {
      return [
        {
          img: this.institutionList,
          title: "Institutions",
          text: "Number of Institution with Public Services",
          path: "institution-service-list",
        },
        {
          img: this.ResponsesDashboard.approvedServices,
          title: "Services",
          text: "Services Offered by Public Institutions",
          path: "list-of-services-Dashboard",
        },
        {
          img: this.ResponsesDashboard.servicesCategory,
          title: "Categories",
          text: "Service Categories",
          path: "list-of-category",
        },
        {
          img: this.ResponsesDashboard.servicesRequirements,
          title: "Requirments",
          text: "Important requirments required on services",
          path: "list-of-requirments",
        },
      ];
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: auto;
}
.filter-blue {
  filter: invert(33%) sepia(100%) saturate(3814%) hue-rotate(192deg)
    brightness(93%) contrast(102%);
}
</style>

