import { apolloClient } from "@/vue-apollo";
import Vue from "vue";
import createNewSurvey from "@/graphql/survey-graphql/createNewSurvey.gql";
import getSurveys from "@/graphql/survey-graphql/getSurveys.gql";
import publicSurveys from "@/graphql/survey-graphql/publicSurveys.gql";
import surveyPayload from "@/graphql/survey-graphql/surveyPayload.gql";
import updateSurveyPayload from "@/graphql/survey-graphql/updateSurveyPayload.gql";
import saveSurveyResponse from "@/graphql/survey-graphql/saveSurveyResponse.gql";
import copySurvey from "@/graphql/survey-graphql/copySurvey.gql";
import surveyAnswers from "@/graphql/survey-graphql/surveyAnswers.gql";
import privateSurvey from "@/graphql/survey-graphql/getPrivateSurvey.gql";
import surveysByStatus from "@/graphql/survey-graphql/getSurveyByStatus.gql";
import updateSurveyStatus from "@/graphql/survey-graphql/updateSurveyStatus.gql";
import surveyAnalytics from "@/graphql/survey-graphql/getSurveyManagementAnalysis.gql";
import allSurveyCategories from "@/graphql/landingPage/allCategories.gql";
import loadPrivateSurveyToRespond from "@/graphql/survey-graphql/loadPrivateSurveyToRespond.gql";
import loadSurveysByCategory from "@/graphql/survey-graphql/getPublicSurveysByCategory.gql";
import fetchSurveyByUid from "@/graphql/survey-graphql/getSurveyByUid.gql";
import addSurveyCategory from "@/graphql/survey-graphql/addSurveyCategory.gql";
import deleteSurveyCategory from "@/graphql/survey-graphql/deleteSurveyCategory.gql";
import surveyAnalysis from "@/graphql/survey-graphql/surveyAnalysis.gql";
import surveyAnalysisPdf from "@/graphql/survey-graphql/getSurveyAnalysisPdf.gql";
import getSurveyRespondentsAnalysis from "@/graphql/survey-graphql/getSurveyRespondentAnalysis.gql";


function downloadFile(filePath) {
	fetch(filePath)
		.then((res) => { return res.blob(); })
		.then((data) => {
			var a = document.createElement("a");
			a.href = window.URL.createObjectURL(data);
			a.download = "report.pdf";
			a.click();
		});
}

const filesRoot = process.env.VUE_APP_FILES_ROOT

const surveyStore = {

	state: {
		surveyList: [],

		publicSurveys: [],

		surveyPayload: "",

		surveyAnswers: [],

		privateSurveyId: "",

		draftSurveys: [],

		activeSurveys: [],

		inActiveSurveys: [],

		surveyAnalysis: [],

		privateSurveyToRespond: [],

		surveyDetails: [],

		surveyAnalytics: [],

		quizDataForPreview: [],

		surveyContent: [],

		quizAnswers: [],

		surveyCategories: [],

		surveyAnalyticsPdf: [],

		surveyRespondentsAnalysis:[]

	},

	mutations: {
		clearSurveyList(state) {
			state.SurveyList = []
		},

		addSurveyList(state, surveys) {
			state.surveyList.push(surveys);
		},
		setPublicSurveys(state, surveys) {
			state.publicSurveys = surveys;
		},
		setSurveyPayload(state, payload) {
			state.surveyPayload = payload;
		},
		setSurveyAnswers(state, data) {
			state.surveyAnswers = data
			localStorage.setItem("answers", data)
		},
		setPrivateSurveyId(state, data) {
			state.privateSurveyId = data;
		},
		setDraftSurveys(state, data) {
			state.draftSurveys.push(data);
		},
		setActiveSurveys(state, data) {
			state.activeSurveys.push(data)
		},
		clearDraftSurveys(state) {
			state.draftSurveys = [];
		},
		clearInactiveSurveys(state) {
			state.inActiveSurveys = [];
		},
		clearActiveSurveys(state) {
			state.activeSurveys = [];
		},
		deleteDraftSurveys(state, data) {
			state.draftSurveys.splice(
				state.draftSurveys.findIndex(({ surveyUniqueId }) => surveyUniqueId === data.surveyUniqueId),
				1
			);

		},
		deleteActiveSurveys(state, data) {
			state.activeSurveys.splice(
				state.activeSurveys.findIndex(({ surveyUniqueId }) => surveyUniqueId === data.surveyUniqueId),
				1
			);
		},
		deleteInactiveSurveys(state, data) {
			state.inActiveSurveys.splice(
				state.inActiveSurveys.findIndex(({ surveyUniqueId }) => surveyUniqueId === data.surveyUniqueId),
				1
			);

		},

		activateSurvey(state, data) {
			state.draftSurveys.splice(
				state.draftSurveys.findIndex(({ surveyUniqueId }) => surveyUniqueId === data.surveyUniqueId),
				1
			);
			state.inActiveSurveys.splice(
				state.inActiveSurveys.findIndex(({ surveyUniqueId }) => surveyUniqueId === data.surveyUniqueId),
				1
			);
		},
		deActivateSurvey(state, data) {
			state.activeSurveys.splice(
				state.activeSurveys.findIndex(({ surveyUniqueId }) => surveyUniqueId === data.surveyUniqueId),
				1
			);
		},
		setInactiveSurveys(state, data) {
			state.inActiveSurveys.push(data);
		},
		setSurveyAnalysis(state, data) {
			state.surveyAnalysis = data
		},
		setPrivateSurveyToRespond(state, data) {
			state.privateSurveyToRespond = data
		},
		setSurveyByUid(state, data) {
			state.surveyDetails = data
		},
		setSurveyAnalytics(state, data) {
			state.surveyAnalytics = data
		},
		saveTheQuizDataForPreview(state, data) {
			state.quizDataForPreview = data;
		},
		setSurveyContent(state, data) {
			state.surveyContent = data
		},

		clearSurveyContent(state) {
			state.surveyContent = [];
		},

		setQuizAnswers(state, data) {
			state.quizAnswers = data
		},
		clearSurveyCategory(state) {
			state.surveyCategories = [];
		},

		setSurveyCategory(state, data) {
			state.surveyCategories.push(data);
		},
		deleteSurveyCategory(state, data) {
			state.surveyCategories.splice(
				state.surveyCategories.findIndex(({ categoryUniqueId }) => categoryUniqueId === data.categoryUniqueId)
			)
		},
		setSurveyAnalyticsPdf(state, data) {
			state.surveyAnalyticsPdf = data;
		},
		clearSurveyAnalyticsPdf(state) {
			state.surveyAnalyticsPdf = [];
		},
		setSurveyRespondentsAnalysis(state, data){
			state.surveyRespondentsAnalysis = data
		}

	},

	getters: {

		getAllSurveys(state) {
			return state.surveyList
		},
		getDraftSurveys(state) {
			return state.draftSurveys
		},
		getActiveSurveys(state) {
			return state.activeSurveys
		},
		getInActiveSurveys(state) {
			return state.inActiveSurveys
		},
		getSurveyAnalysis(state) {
			return state.surveyAnalysis
		},

		getSurveyPrivaate: (state) => (state.privateSurvey),

		getPublicSurveys: (state) => (state.publicSurveys),

		getSurveyPayload: (state) => (state.surveyPayload),

		getSurveyAnswers(state) {

			return JSON.parse(state.surveyAnswers.answers)
		},

		getPrivateSurveyId(state) {
			return state.privateSurveyId
		},
		getPrivateSurveyToRespond(state) {
			return state.privateSurveyToRespond
		},
		getSurveyByUid(state) {
			return state.surveyDetails
		},
		getSurveyAnalytics(state) {
			return state.surveyAnalytics
		},
		getQuizDataForPreview(state) {
			return state.quizDataForPreview
		},
		getSurveyContent(state) {
			return state.surveyContent
		},
		getQuizAnswers(state) {
			return state.quizAnswers
		},
		getAllSurveyCategories(state) {
			return state.surveyCategories;
		},
		getSurveyAnalyticsPdf(state) {
			return state.surveyAnalyticsPdf;
		},
		getSurveyRespondentsAnalysis(state){
			return state.surveyRespondentsAnalysis
		}

	},

	actions: {
		async newSurvey(context, data) {
			// console.log("The data to be sent is", data);
			await apolloClient
				.mutate({
					mutation: createNewSurvey,
					variables: { ...data }

				})
				.then((response) => {
					if (response.data.createNewSurvey.statusCode == 200) {
						let dataTemp = {
							surveyNumber: response.data.createNewSurvey.surveyNumber,
							surveyUniqueId: response.data.createNewSurvey.surveyUniqueId,
							...data
						}
						context.commit("setDraftSurveys", dataTemp);

						if (data.surveyType == 'survey') {
							context.dispatch("fetchSurveyAnalysis", "survey");
							Vue.swal({
								icon: "success",
								text: "Survey created successfully!",
								showConfirmButton: false,
								timer: 3000,
							});

						}
						else {
							context.dispatch("fetchSurveyAnalysis", "quiz");
							Vue.swal({
								icon: "success",
								text: "Quiz created successfully!",
								showConfirmButton: false,
								timer: 3000,
							});
						}
					} else {
						if (data.surveyType == 'survey') {

							Vue.swal({
								icon: "error",
								title: "Oops...",
								text: "Survey creation failed!",
								showConfirmButton: false,
								timer: 3000,
							});
						}
						else {
							Vue.swal({
								icon: "error",
								title: "Oops...",
								text: "Quiz creation failed!",
								showConfirmButton: false,
								timer: 3000,
							});
						}
					}
				})
				.catch((error) => {
					error

				});
		},

		async surveysList(context) {
			context.commit("clearSurveyList");
			await apolloClient
				.query({
					fetchPolicy: "no-cache",
					query: getSurveys,
				})
				.then((response) => {
					response.data.loadUserSurveys.forEach(element => {
						context.commit("addSurveyList", element);
					});
				});
		},
		async fetchPublicSurveys(context) {
			await apolloClient
				.query({
					fetchPolicy: "no-cache",
					query: publicSurveys,
				})
				.then((response) => {
					context.commit("setPublicSurveys", response.data.loadPublicSurveysAll);
				});
		},

		async loadSurveyPayload({ commit }, surveyId) {
			await apolloClient.query({
				fetchPolicy: "no-cache",
				query: surveyPayload,
				variables: {
					surveyUniqueId: surveyId
				}
			}).then((response) => {
				console.log("The survey payload is", response.data);

				commit("setSurveyPayload", response.data.loadPayload.payload);
			})
				.catch((error) => {
					error
				})
		},

		async updateSurveyPayload(context, updatedSurvey) {
			await apolloClient.mutate({
				mutation: updateSurveyPayload,
				variables: {
					updatedSurvey: updatedSurvey,
				}

			})
		},

		async postSurveyResponse(context, data) {

			await apolloClient.mutate({

				mutation: saveSurveyResponse,

				variables: data

			})

		},

		async surveyCopying(context, data) {
			await apolloClient.mutate({
				mutation: copySurvey,
				variables: data
			})
				.then((response) => {
					response

				})
		},

		async loadSurveyAnswers(context, surveyId) {
			await apolloClient.query({
				fetchPolicy: "no-cache",
				query: surveyAnswers,
				variables: {
					surveyUniqueId: surveyId
				}
			})
				.then((response) => {
					context.commit("setSurveyAnswers", response.data.loadSurveyAnswers)
				})
		},

		async fetchSurveyByNumber(context, surveyNumber) {
			await apolloClient.query({
				fetchPolicy: "no-cache",
				query: privateSurvey,
				variables: {
					surveyNumber: surveyNumber
				}
			})
				.then((response) => {
					if (response.data.getPrivateSurvey.status == 200) {
						context.commit("setPrivateSurveyId", response.data.getPrivateSurvey.uniqueId);
					}
					else {

						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Survey Does'nt exist!",
							showConfirmButton: false,
							timer: 1500,
						});

					}


				})

		},

		async fetchDraftSurveys(context, data) {
			context.commit('clearDraftSurveys');
			apolloClient.query({
				fetchPolicy: "no-cache",
				query: surveysByStatus,
				variables: {
					surveyStatus: data.surveyStatus,
					surveyType: data.surveyType
				}
			})
				.then((response) => {
					// console.log("The draft surveys", response);
					response.data.loadUserSurveys.forEach(singleDraftSurvey => {
						context.commit("setDraftSurveys", singleDraftSurvey);
					})
				})
		},

		async fetchActiveSurveys(context, data) {
			context.commit('clearActiveSurveys')

			apolloClient.query({
				fetchPolicy: "no-cache",
				query: surveysByStatus,
				variables: {
					surveyStatus: data.surveyStatus,
					surveyType: data.surveyType
				}
			})
				.then((response) => {
					response.data.loadUserSurveys.forEach(activeSurvey => {
						context.commit("setActiveSurveys", activeSurvey);
					})

				})
		},

		async fetchInactiveSurveys(context, data) {
			context.commit('clearInactiveSurveys')
			apolloClient.query({
				fetchPolicy: "no-cache",
				query: surveysByStatus,
				variables: {
					surveyStatus: data.surveyStatus,
					surveyType: data.surveyType
				}
			})
				.then((response) => {
					response.data.loadUserSurveys.forEach(inactiveSurvey => {
						context.commit("setInactiveSurveys", inactiveSurvey);
					})

				})
		},

		async activateSurvey(context, data, type) {
			apolloClient.mutate({
				mutation: updateSurveyStatus,
				variables: {
					surveyStatus: data.surveyStatus,
					uniqueId: data.uniqueId
				}
			})
				.then((response) => {
					type
					if (response.data.updateSurveyStatus.status == 200) {
						context.commit('activateSurvey', { surveyUniqueId: data.uniqueId })

						if (data.surveyType == 'quiz') {
							context.dispatch("fetchSurveyAnalysis", "quiz");
							Vue.swal({
								icon: "success",
								text: "quiz activated successfully!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
						else {
							context.dispatch("fetchSurveyAnalysis", "survey");
							Vue.swal({
								icon: "success",
								text: "survey activated successfully!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
					}
					else {
						if (data.surveyType == 'quiz') {
							Vue.swal({
								icon: "error",
								title: "Oops...",
								text: "Quiz activation failed!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
						else {

							Vue.swal({
								icon: "error",
								title: "Oops...",
								text: "Survey activation failed!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
					}

				})
		},

		async deactivateSurvey(context, data) {
			apolloClient.mutate({
				mutation: updateSurveyStatus,
				variables: {
					surveyStatus: data.surveyStatus,
					uniqueId: data.uniqueId
				}
			})
				.then((response) => {

					if (response.data.updateSurveyStatus.status == 200) {
						context.commit('deActivateSurvey', { surveyUniqueId: data.uniqueId })

						if (data.surveyType == 'quiz') {
							context.dispatch("fetchSurveyAnalysis", "quiz");
							Vue.swal({
								icon: "success",
								text: "quiz deactivated successfully!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
						else {
							context.dispatch("fetchSurveyAnalysis", "survey");
							Vue.swal({
								icon: "success",
								text: "survey deactivated successfully!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
					}
					else {
						if (data.surveyType == 'quiz') {
							Vue.swal({
								icon: "error",
								title: "Oops...",
								text: "Quiz deactivation failed!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
						else {

							Vue.swal({
								icon: "error",
								title: "Oops...",
								text: "Survey deactivation failed!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
					}
				})
		},

		async fetchSurveyAnalyticsPdf(context, data) {
			context.commit("clearSurveyAnalyticsPdf");
			apolloClient.query({
				query: surveyAnalysisPdf,
				variables: { ...data }
			})
				.then((response) => {
					if (response.data.getSurveyAnalysisPdf) {

						downloadFile(
							filesRoot + response.data.getSurveyAnalysisPdf.surveyAnalysisPdf
						)

						// context.commit("setSurveyAnalyticsPdf", response.data.getSurveyAnalysisPdf);
					}
					else {
						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Data not found",
							showConfirmButton: false,
							timer: 1500,
						});
					}
				})
		},

		async removeSurvey(context, data) {
			apolloClient.mutate({
				mutation: updateSurveyStatus,
				variables: {
					surveyStatus: data.surveyStatus,
					uniqueId: data.uniqueId
				}
				// variables: data

			})
				.then((response) => {

					if (response.data.updateSurveyStatus.status == 200) {
						context.commit('deleteDraftSurveys', { surveyUniqueId: data.uniqueId })
						context.commit('deleteActiveSurveys', { surveyUniqueId: data.uniqueId })
						context.commit('deleteInactiveSurveys', { surveyUniqueId: data.uniqueId })


						if (data.surveyType == 'quiz') {
							context.dispatch("fetchSurveyAnalysis", "quiz");
							Vue.swal({
								icon: "success",
								text: "quiz deleted successfully!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
						else if (data.surveyType == 'survey') {
							context.dispatch("fetchSurveyAnalysis", "survey");
							Vue.swal({
								icon: "success",
								text: "survey deleted successfully!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
					}
					else {
						if (data.surveyType == 'quiz') {
							Vue.swal({
								icon: "error",
								title: "Oops...",
								text: "Quiz deletion failed!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
						else {

							Vue.swal({
								icon: "error",
								title: "Oops...",
								text: "Survey deletion failed!",
								showConfirmButton: false,
								timer: 1500,
							});
						}
					}
				})
		},

		async fetchSurveyAnalysis(context, data) {
			apolloClient.query({
				fetchPolicy: "no-cache",
				query: surveyAnalytics,
				variables: {
					surveyType: data
				}
			})
				.then((response) => {
					context
					// console.log("The survey analysis response", response);
					context.commit("setSurveyAnalysis", response.data.getSurveyManagementAnalysis);

				})
		},

		async loadPrivateSurveyToRespond(context, data) {
			apolloClient.query({
				fetchPolicy: "no-cache",
				query: loadPrivateSurveyToRespond,
				variables: {...data}
			})
				.then((response) => {
					context.commit("setPrivateSurveyToRespond", response.data.loadPrivateSurveyToRespond)
				})
		},

		async fetchSurveyByUid(context, data) {
			apolloClient.query({
				fetchPolicy: "no-cache",
				query: fetchSurveyByUid,
				variables: {
					surveyUniqueId: data
				}
			})
				.then((response) => {
					context.commit("setSurveyByUid", response.data.loadSurveyByUid);
				})
		},

		async loadSurveyByCategory(context, data) {
			await apolloClient.query({
				fetchPolicy: "no-cache",
				query: loadSurveysByCategory,
				variables: {
					categoryUid: data
				}
			})
				.then((response) => {

					context.commit("setPublicSurveys", response.data.loadSurveysByCategory);

				})
		},

		async addSurveyCategory(context, data) {

			apolloClient.mutate({
				mutation: addSurveyCategory,
				variables: { ...data }
			})
				.then((response) => {

					if (response.data.addSurveyCategory.status == 200) {
						let dataTemp = {
							categoryUniqueId: response.data.addSurveyCategory.categoryUniqueId,
							...data
						}

						context.commit('setSurveyCategory', dataTemp);

						Vue.swal({
							icon: "success",
							text: "category added successfully!",
							showConfirmButton: false,
							timer: 1500,
						});
					}
					else {
						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Error!",
							showConfirmButton: false,
							timer: 1500,
						});
					}
				})
		},

		async deleteSurveyCategory(context, data) {
			await apolloClient.mutate({
				mutation: deleteSurveyCategory,
				variables: { ...data }
			})
				.then((response) => {
					if (response.data.deleteSurveyCategory.status == 200) {
						context.commit('deleteSurveyCategory', { categoryUniqueId: data.categoryUniqueId })
						Vue.swal({
							icon: "success",
							text: "category deleted successfully!",
							showConfirmButton: false,
							timer: 1500,
						});
					}
					else {
						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Error occured!",
							showConfirmButton: false,
							timer: 1500,
						});
					}
				})
		},

		async fetchAllSurveyCategories(context) {
			context.commit('clearSurveyCategory');
			await apolloClient.query({
				fetchPolicy: "no-cache",
				query: allSurveyCategories,
			})

				.then((response) => {
					response.data.allSurveyCategories.forEach(surveyCategory => {
						context.commit("setSurveyCategory", surveyCategory);
					})
				})
		},
		async fetchSurveyAnalytics(context, data) {
			await apolloClient.query({
				fetchPolicy: 'no-cache',
				query: surveyAnalysis,
				variables: {
					surveyUniqueId: data.surveyUniqueId
				}
			})
				.then((response) => {
					context.commit('setSurveyAnalytics', response.data.getSurveyAnalysis);
				})
		},
		async fetchSurveyRespondentsAnalysis(context){
			apolloClient.query({
				query: getSurveyRespondentsAnalysis
			})
			.then((response)=>{
				context.commit('setSurveyRespondentsAnalysis',response.data.getSurveyRespondentsAnalysis)
				// console.log("The response is", response.data.getSurveyRespondentsAnalysis);
			})
		}
	}
};

export default surveyStore;
