import LoginPageView from "@/views/Auth/LoginPageView.vue";
import ForgotPasswordView from "@/views/Auth/ForgotPasswordView.vue";
import ActivateAccount from "@/views/Auth/ActivateAccount.vue";
import CustomActivateAccount from '@/views/Auth/CustomActivateAccount.vue'
import ResetPasswordView from "@/views/Auth/ResetPasswordView.vue"

import Auth from "@/views/Auth/Auth.vue";

const authRouter = {
  path: "/auth",
  name: "Auth",
  component: Auth,
  children: [
    {
      path: "login",
      name: "Login",
      component: LoginPageView,
    },
    {
      path: "forgot_password",
      name: "ForgotPasswordView",
      component: ForgotPasswordView,
    },
    {
      path: "activate/:token",
      name: "ActivateAccount",
      component: ActivateAccount,
    },
    {
      path: "activate/:userId/:token",
      name: "CustomActivateAccount",
      component: CustomActivateAccount,
    },
    {
      path: "password-reset/:token",
      name: "ResetPasswordView",  
      component: ResetPasswordView
    }
  ],
};
export default authRouter;