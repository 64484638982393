<template>
  <LoginPageComponent
  />
</template>

<script>
import LoginPageComponent from "@/components/Auth/LoginPageComponent.vue";
export default {
  name: "LoginPageView",
  components: {
    LoginPageComponent,
  }
};
</script>
