<template>
  <v-app class="background">
    <Topnav />
    <Sidenav />
    <TopCardsComponent />
    <v-divider class="mx-8"></v-divider>
    <v-container fluid>
      <v-row class="mx-3">
        <v-col class="text-right">
          <Button v-if="hasAccess('CAN_CREATE_PACKAGE')" />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <PackagesTable v-if="hasAccess('CAN_VIEW_PACKAGE')" />
    </v-container>
  </v-app>
</template>
<script>

import Topnav from "@/components/navigators/Topnav.vue";
import Sidenav from "@/components/navigators/Sidenav.vue";
import TopCardsComponent from "@/components/systemAdmin/packageMagement/TopCardsComponent.vue";
import Button from "@/components/systemAdmin/packageMagement/Button.vue";
import PackagesTable from "@/components/systemAdmin/packageMagement/PackagesTable.vue";
export default {
  name: "PackagesManagement",
  components: {
    Topnav,
    Sidenav,
    Button,
    TopCardsComponent,
    PackagesTable,
  },
};
</script>

<style scoped>
.background {
  background-image: url("../../assets/images/background1.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
</style>