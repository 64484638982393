<template>
  <v-card elevation="10">
    <v-card-title class="blue--text">
      <h2 style="color: Orange">Pending Quizes</h2>

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>

    <v-data-table :headers="headers" :items="quizesToRespond" :search="search">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          slot="activator"
          color="primary"
          outlined
          v-bind="attrs"
          style="text-transform: unset"
          :to="'/response/' + item.surveyUniqueId"
        >
          Respond
        </v-btn>
      </template>

      <v-list dense> </v-list>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      surveyTitle: null,
      dataCreated: false,
      dialog: false,
      tab: null,
      search: "",
      surveyUniqueId: "",
      menu1: false,
      menu2: false,
      access: null,
      categoryUniqueId: "",
      item: "",
      headers: [
        {
          text: "Survey Number",
          align: "start",
          sortable: false,
          value: "surveyNumber",
        },
        { text: "survey title", value: "surveyTitle" },
        { text: "Category", value: "surveyCategory" },
        { text: "End date", value: "surveyEndDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {},
  async mounted() {
    await this.$store.dispatch("loadPrivateSurveyToRespond", {surveyType: 'quiz'});
  },
  computed: {
    ...mapGetters({
      surveysList: "getAllSurveys",
      draftSurvey: "getDraftSurveys",
      activeSurveys: "getActiveSurveys",
      inActiveSurveys: "getInActiveSurveys",
      surveyAnalysis: "setSurveyAnalysis",
      categories: "getAllSurveyCategories",
      quizesToRespond: "getPrivateSurveyToRespond",
    }),
  },
  // watch: {
  //   async newDataCreated(newData) {
  //     if (newData) {
  //       await this.$store.dispatch("surveysList");
  //     } else {
  //     }
  //   },
  // },
};
</script>

<style scoped>
.scroll {
  overflow-y: auto;
}
.background {
  /* background-image: url("../../assets/images/background1.png"); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
.filter-blue {
  filter: invert(33%) sepia(100%) saturate(3814%) hue-rotate(192deg)
    brightness(93%) contrast(102%);
}
</style>

